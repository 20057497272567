/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
.produitsMain, .destockage, .resultat {
  display: flex;
  flex-direction: column;
  margin: 0px 100px;
}
.produitsMain .recap, .destockage .recap, .resultat .recap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Jost", "Lato", sans-serif;
  background-color: #FFFFFF;
  gap: 30px;
  padding-top: 20px;
}
.produitsMain .recap_nbArticles, .destockage .recap_nbArticles, .resultat .recap_nbArticles {
  display: flex;
  font-size: 16px;
}
.produitsMain .recap_stockage, .destockage .recap_stockage, .resultat .recap_stockage {
  display: none;
  align-items: center;
  text-align: center;
  background-color: #D8D8D8;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3137254902);
  padding: 5px 15px;
  gap: 10px;
}
.produitsMain .recap_stockage .iconeX, .destockage .recap_stockage .iconeX, .resultat .recap_stockage .iconeX {
  height: auto;
  display: flex;
  flex-direction: row;
}
.produitsMain .recap_stockage .iconeX i, .destockage .recap_stockage .iconeX i, .resultat .recap_stockage .iconeX i {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.produitsMain .recap_stockage.visible, .destockage .recap_stockage.visible, .resultat .recap_stockage.visible {
  display: flex !important;
}
.produitsMain .listeProduits, .destockage .listeProduits, .resultat .listeProduits {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 50px 0px 50px;
  gap: 2.66%;
  /* Card Article */
}
.produitsMain .listeProduits_ligne, .destockage .listeProduits_ligne, .resultat .listeProduits_ligne {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}
.produitsMain .listeProduits .cardArticle, .destockage .listeProduits .cardArticle, .resultat .listeProduits .cardArticle {
  width: 23%;
  display: flex;
  flex-direction: column;
}
.produitsMain .listeProduits .cardArticle_photo, .destockage .listeProduits .cardArticle_photo, .resultat .listeProduits .cardArticle_photo {
  width: 100%;
  position: relative;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.5019607843);
  transition: all 2s ease-in-out;
}
.produitsMain .listeProduits .cardArticle_photo::after, .destockage .listeProduits .cardArticle_photo::after, .resultat .listeProduits .cardArticle_photo::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.produitsMain .listeProduits .cardArticle_photo:hover, .destockage .listeProduits .cardArticle_photo:hover, .resultat .listeProduits .cardArticle_photo:hover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5647058824);
}
.produitsMain .listeProduits .cardArticle_photo:hover .cardArticle_photo_un, .destockage .listeProduits .cardArticle_photo:hover .cardArticle_photo_un, .resultat .listeProduits .cardArticle_photo:hover .cardArticle_photo_un {
  display: none;
}
.produitsMain .listeProduits .cardArticle_photo:hover .cardArticle_photo_deux, .destockage .listeProduits .cardArticle_photo:hover .cardArticle_photo_deux, .resultat .listeProduits .cardArticle_photo:hover .cardArticle_photo_deux {
  display: flex;
}
.produitsMain .listeProduits .cardArticle_photo img, .destockage .listeProduits .cardArticle_photo img, .resultat .listeProduits .cardArticle_photo img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.produitsMain .listeProduits .cardArticle_photo_deux, .destockage .listeProduits .cardArticle_photo_deux, .resultat .listeProduits .cardArticle_photo_deux {
  display: none;
}
.produitsMain .listeProduits .cardArticle_info, .destockage .listeProduits .cardArticle_info, .resultat .listeProduits .cardArticle_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0px 70px 0px;
  gap: 5px;
}
.produitsMain .listeProduits .cardArticle_info_nom, .destockage .listeProduits .cardArticle_info_nom, .resultat .listeProduits .cardArticle_info_nom {
  min-height: 38px;
  font-size: 16px;
}
.produitsMain .listeProduits .cardArticle_info_category, .destockage .listeProduits .cardArticle_info_category, .resultat .listeProduits .cardArticle_info_category {
  font-size: 14px;
}
.produitsMain .listeProduits .cardArticle_info_prix, .destockage .listeProduits .cardArticle_info_prix, .resultat .listeProduits .cardArticle_info_prix {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 5px;
  gap: 3%;
}
.produitsMain .listeProduits .cardArticle_info_prix_gauche, .destockage .listeProduits .cardArticle_info_prix_gauche, .resultat .listeProduits .cardArticle_info_prix_gauche {
  font-size: 20px;
  font-weight: bold;
}
.produitsMain .listeProduits .cardArticle_info_prix_droite, .destockage .listeProduits .cardArticle_info_prix_droite, .resultat .listeProduits .cardArticle_info_prix_droite {
  font-size: 14px;
}
.produitsMain .listeProduits .cardArticle_info_prix_barre, .destockage .listeProduits .cardArticle_info_prix_barre, .resultat .listeProduits .cardArticle_info_prix_barre {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 3%;
  font-size: 16px;
  color: red;
  text-decoration: line-through;
  padding-top: 10px;
}
.produitsMain .listeProduits .cardArticle_info_prix_barre_gauche, .destockage .listeProduits .cardArticle_info_prix_barre_gauche, .resultat .listeProduits .cardArticle_info_prix_barre_gauche {
  font-size: 16px;
  font-weight: bold;
}
.produitsMain .listeProduits .cardArticle_info_prix_barre_droite, .destockage .listeProduits .cardArticle_info_prix_barre_droite, .resultat .listeProduits .cardArticle_info_prix_barre_droite {
  font-size: 14px;
}

/* Bouton Afficher Plus de Produits */
.plusProduits {
  display: flex;
  position: relative;
}
.plusProduits_seul {
  width: 100%;
  display: flex;
  justify-content: center;
}
.plusProduits_ensemble {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.plusProduits_gauche {
  width: 25%;
}
.plusProduits_centre {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.plusProduits_droite {
  width: 25%;
  display: flex;
  justify-content: right;
  text-align: center;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 15px;
  padding-top: 10px;
}
.plusProduits_bouton {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  background-color: #373a3c;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 22px;
  transition: all 0.6s;
}
.plusProduits_bouton.bouton_encore:hover {
  opacity: 0.7;
}
.plusProduits_bouton.bouton_fini {
  opacity: 0.7;
}
.plusProduits_fleche {
  animation: plusProductArrow 4s linear infinite;
  transition: all 0.6s;
}
.plusProduits_fleche:hover {
  opacity: 0.7;
}
.plusProduits_spinner {
  top: -17.5px;
  left: 10%;
}
.plusProduits_spinner div:after {
  top: 12px;
  left: 37px;
}

.bullePromo {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #FFFFFF;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  background-color: red;
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 2;
}

.bulleStock {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #FFFFFF;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  background-color: #373a3c;
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 2;
}

.drapeau {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 2;
  height: 30px;
  width: 30px;
}

.ariane {
  padding: 30px 0px 20px 0px;
}
.ariane ul {
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size: 20px;
}
.ariane li {
  display: flex;
  flex-direction: row;
}
.ariane li:not(:last-child) a, .ariane li:not(:last-child) span {
  color: #373a3c;
  font-weight: bold;
}
.ariane li:not(:last-child) a:hover, .ariane li:not(:last-child) span:hover {
  opacity: 0.7;
}
.ariane li:not(:last-child)::after {
  display: inline-block;
  margin: 0px 16px;
  content: ">";
}

.filtres {
  position: sticky;
  top: 69px;
  z-index: 20;
  display: flex;
  justify-content: left;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  background-color: #FFFFFF;
}
.filtres_bloc {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  box-shadow: 0px 10px 10px -13px rgba(0, 0, 0, 0.3137254902);
  padding-bottom: 10px;
  margin-left: 50px;
  gap: 20px;
}
.filtres_bloc .boutonsFiltre, .filtres_bloc .boutonsFiltreSize {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 7px 5px 10px;
}
.filtres_bloc .boutonsFiltre:hover .boutonsFiltre_filtre, .filtres_bloc .boutonsFiltreSize:hover .boutonsFiltre_filtre {
  opacity: 0.7;
}
.filtres_bloc .boutonsFiltre_entier, .filtres_bloc .boutonsFiltreSize_entier {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-right: 15px;
}
.filtres_bloc .boutonsFiltre_icones, .filtres_bloc .boutonsFiltreSize_icones {
  display: flex;
  height: auto;
  width: auto;
}
.filtres_bloc .boutonsFiltre_icones img, .filtres_bloc .boutonsFiltreSize_icones img {
  height: 18px;
  width: 20px;
}
.filtres_bloc .boutonsFiltre_enStock, .filtres_bloc .boutonsFiltreSize_enStock {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: #373a3c 0px 0px 3px;
  padding: 9px 20px;
  cursor: pointer;
}
.filtres_bloc .boutonsFiltre_enStock.stockOui, .filtres_bloc .boutonsFiltreSize_enStock.stockOui {
  color: #FFFFFF;
  background-color: #045803 !important;
}
.filtres_bloc .boutonsFiltre_enStock.stockOui:hover, .filtres_bloc .boutonsFiltreSize_enStock.stockOui:hover {
  background-color: #28292A !important;
  transition: all 0.2s linear;
}
.filtres_bloc .boutonsFiltre_enStock.stockNon, .filtres_bloc .boutonsFiltreSize_enStock.stockNon {
  color: #28292A;
}
.filtres_bloc .boutonsFiltre_enStock.stockNon:hover, .filtres_bloc .boutonsFiltreSize_enStock.stockNon:hover {
  color: #FFFFFF;
  background-color: #28292A !important;
  transition: all 0.2s linear;
}
.filtres_bloc ul {
  display: none;
  flex-direction: column;
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  flex-wrap: wrap;
  list-style: none;
  font-size: 12px;
  padding-left: 20px;
  gap: 5px;
}
.filtres_bloc h2 {
  font-size: 14px;
  font-weight: bold;
}
.filtres_produit_titre {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #28292A;
  padding-bottom: 10px;
  gap: 20px;
  cursor: pointer;
}
.filtres_produit_titre_fleche {
  display: flex;
  font-size: 25px;
  font-weight: bolder;
  transition: transform 0.2s;
}
.filtres_produit_bouton {
  display: flex;
  justify-content: center;
}
.filtres_chargementStock {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.filtres_chargementStock .stockSelected {
  color: #FFFFFF;
  background-color: red;
  transition: all 0.2s linear;
}
.filtres_chargementStock .stockSelected:hover {
  color: #28292A;
  background-color: #D8D8D8 !important;
  transition: all 0.2s linear;
}
.filtres_spinner {
  right: 66px;
  bottom: 66px;
}
.filtres_spinner div:after {
  top: 12px;
  left: 37px;
}

.menuDeroulant {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.aide {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 80px 0px;
  background-color: #D8D8D8;
  gap: 15px;
}
.aide_paragraphe_titre {
  font-weight: bold;
}
.aide_paragraphe_texte {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 16px;
}
.aide_ligne {
  align-self: center;
  width: 50%;
  border-bottom: 3px solid #373a3c;
}

.catSelected {
  background-color: #FAFAFA;
  border: 1px solid #373a3c;
  border-radius: 20px;
  box-shadow: #373a3c 0px 0px 10px;
}
.catSelected .boutonsFiltre_entier {
  font-weight: bold;
}
.catSelected .boutonsFiltre_enStock {
  display: flex !important;
}

/* Grand Ecran */
@media all and (min-width: 1799px) {
  .produitsMain .recap_nbArticles, .destockage .recap_nbArticles, .resultat .recap_nbArticles {
    font-size: 20px;
  }
  .produitsMain .filtres_bloc, .destockage .filtres_bloc, .resultat .filtres_bloc {
    margin-left: 80px;
  }
  .produitsMain .filtres_bloc h2, .destockage .filtres_bloc h2, .resultat .filtres_bloc h2 {
    font-size: 20px;
  }
  .produitsMain .filtres_bloc ul, .destockage .filtres_bloc ul, .resultat .filtres_bloc ul {
    font-size: 18px;
  }
  .produitsMain .listeProduits, .destockage .listeProduits, .resultat .listeProduits {
    padding: 10px 80px 0px 80px;
  }
  .produitsMain .listeProduits .cardArticle_info_nom, .destockage .listeProduits .cardArticle_info_nom, .resultat .listeProduits .cardArticle_info_nom {
    font-size: 18px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_gauche, .destockage .listeProduits .cardArticle_info_prix_gauche, .resultat .listeProduits .cardArticle_info_prix_gauche {
    font-size: 22px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_droite, .destockage .listeProduits .cardArticle_info_prix_droite, .resultat .listeProduits .cardArticle_info_prix_droite {
    font-size: 16px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_barre, .destockage .listeProduits .cardArticle_info_prix_barre, .resultat .listeProduits .cardArticle_info_prix_barre {
    font-size: 18px;
  }
}
/* Petit Ecran */
@media all and (max-width: 1199px) {
  .produitsMain, .destockage, .resultat {
    margin: 0px 65px;
  }
  .produitsMain .filtres_bloc, .destockage .filtres_bloc, .resultat .filtres_bloc {
    margin-left: 0px;
  }
  .produitsMain .listeProduits, .destockage .listeProduits, .resultat .listeProduits {
    padding: 20px 35px 0px 35px;
    gap: 3.5%;
  }
  .produitsMain .listeProduits .cardArticle, .destockage .listeProduits .cardArticle, .resultat .listeProduits .cardArticle {
    width: 31%;
  }
  .produitsMain .listeProduits .cardArticle_info_prix, .destockage .listeProduits .cardArticle_info_prix, .resultat .listeProduits .cardArticle_info_prix {
    font-size: 18px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_barre, .destockage .listeProduits .cardArticle_info_prix_barre, .resultat .listeProduits .cardArticle_info_prix_barre {
    font-size: 14px;
  }
  .ariane ul {
    font-size: 18px;
  }
  .plusProduits_bouton {
    font-size: 20px;
  }
  .aide {
    margin: 80px 0px;
  }
}
/* Tablet */
@media all and (max-width: 767px) {
  .produitsMain, .destockage, .resultat {
    margin: 0px 50px;
  }
  .produitsMain .filtres, .destockage .filtres, .resultat .filtres {
    position: static;
  }
  .produitsMain .filtres_bloc, .destockage .filtres_bloc, .resultat .filtres_bloc {
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    gap: 30px;
  }
  .produitsMain .filtres_produit, .destockage .filtres_produit, .resultat .filtres_produit {
    width: 75%;
  }
  .produitsMain .filtres_produit_titre, .destockage .filtres_produit_titre, .resultat .filtres_produit_titre {
    border-bottom: 1px solid #28292A;
  }
  .produitsMain .filtres_produit_titre h2, .destockage .filtres_produit_titre h2, .resultat .filtres_produit_titre h2 {
    font-size: 14px;
  }
  .produitsMain .filtres_produit_titre_fleche, .destockage .filtres_produit_titre_fleche, .resultat .filtres_produit_titre_fleche {
    font-size: 18px;
  }
  .produitsMain .recap, .destockage .recap, .resultat .recap {
    top: 69px;
    margin-top: 10px;
  }
  .produitsMain .listeProduits, .destockage .listeProduits, .resultat .listeProduits {
    padding: 10px 25px 0px 25px;
    gap: 5%;
  }
  .produitsMain .listeProduits .cardArticle, .destockage .listeProduits .cardArticle, .resultat .listeProduits .cardArticle {
    width: 47.5%;
  }
  .produitsMain .listeProduits .cardArticle_info_prix, .destockage .listeProduits .cardArticle_info_prix, .resultat .listeProduits .cardArticle_info_prix {
    font-size: 18px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_barre, .destockage .listeProduits .cardArticle_info_prix_barre, .resultat .listeProduits .cardArticle_info_prix_barre {
    font-size: 14px;
  }
  .plusProduits_spinner {
    top: -8px;
    right: 15%;
  }
  .ariane li:not(:last-child)::after {
    margin: 0px 8px;
  }
  .aide {
    margin: 40px 0px;
  }
}
/* Smartphone */
@media all and (max-width: 549px) {
  .produitsMain, .destockage, .resultat {
    margin: 0px 25px;
  }
  .produitsMain .listeProduits, .destockage .listeProduits, .resultat .listeProduits {
    padding: 10px 15px 0px 15px;
  }
  .produitsMain .listeProduits .cardArticle, .destockage .listeProduits .cardArticle, .resultat .listeProduits .cardArticle {
    width: 100%;
  }
  .produitsMain .listeProduits .cardArticle_info_nom, .destockage .listeProduits .cardArticle_info_nom, .resultat .listeProduits .cardArticle_info_nom {
    min-height: 0px;
    font-size: 18px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_gauche, .destockage .listeProduits .cardArticle_info_prix_gauche, .resultat .listeProduits .cardArticle_info_prix_gauche {
    font-size: 20px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_droite, .destockage .listeProduits .cardArticle_info_prix_droite, .resultat .listeProduits .cardArticle_info_prix_droite {
    font-size: 16px;
  }
  .produitsMain .listeProduits .cardArticle_info_prix_barre, .destockage .listeProduits .cardArticle_info_prix_barre, .resultat .listeProduits .cardArticle_info_prix_barre {
    font-size: 14px !important;
  }
  .produitsMain .plusProduits_droite, .destockage .plusProduits_droite, .resultat .plusProduits_droite {
    font-size: 13px;
    align-items: center;
  }
  .plusProduits_bouton {
    font-size: 16px;
    padding: 5px 15px;
  }
  .ariane ul {
    font-size: 16px;
  }
  .aide {
    padding: 10px;
  }
  .aide_paragraphe_titre {
    font-size: 16px;
  }
  .aide_paragraphe_texte {
    font-size: 14px;
  }
}/*# sourceMappingURL=Produits.css.map */