@import "../../utils/Utils.scss";

/* Code */
.articleMain {
    @include display-flex(column);
    margin: 0px 100px;

    & .navigationArticle {
        padding: 10px 0px 20px 0px;

        /* &_conteneur {
            height: 100%;
            width: 100%;
            @include display-flex(column);
            align-self: center;
            gap: 30px;
        } */

        & ul {
            display: flex;
            text-align: center;
            flex-wrap: wrap;
            padding: 0px;
            font-size: 18px;
            line-height: 2;
        }

        & li {
            display: flex;
        }
    
        & li:not(:last-child) a, & li:not(:last-child) span {
            color: $primary-color;
            font-weight: bolder;
        }
    
        & li:not(:last-child)::after {
            display: inline-block;
            margin: 0px 16px;
            content: ">";
        }
    }

    & .article {
        width: 100%;
        @include display-flex(row);
        gap: 10%;

        &_buyBox {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.75);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 50;

            &_display {
                display: flex;
                margin: 0px 50px;
                padding: 20px;
                border: 1px solid $white-color;
                border-radius: 20px;
                background-color: rgba($color: $white-color, $alpha: 0.9);

                &_texte {
                    @include display-flex(column);
                    align-items: center;
                    text-align: center;
                }
            }

            &_cross {
                position: absolute;
                top: 30%;
                right: 5%;
                color: $white-color;
                transition: all 0.4s;
                cursor: pointer;
                z-index: 100;
            }
        }
    
        &_gauche {
            width: 45%;

            &_blocPhoto {
                position: relative;

                &_photoPrincipale {
                    width: 100%;
                    position: relative;
                    box-shadow: 0px 0px 15px 1px #00000050;
                    transition: all 0.4s;
                    cursor: zoom-in;
    
                    &:hover {
                        opacity: 0.5;
                    }
                    
                    &::after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }
    
                    & img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
    
                    &_fullscreen {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.95);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 50;
    
                        & img {
                            max-width: 90%;
                            max-height: 90%;
                            object-fit: contain;
                            cursor: zoom-out;
                        }
                    }
                }

                &_chevronGauche, &_chevronDroite {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 10px 5px;
                    color: rgba($color: $black-color, $alpha: 0.8);
                    background-color: rgba($color: $white-color, $alpha: 0.4);
                    transition: all 0.4s;
                    cursor: pointer;
    
                    &:hover {
                        color: rgba($color: $black-color, $alpha: 1);
                        background-color: rgba($color: $white-color, $alpha: 1);
                    }
                }
    
                &_chevronGauche {
                    left: 5px;
                }
    
                &_chevronDroite {
                    right: 5px;
                }

                &_chevronGaucheFullscreen, &_chevronDroiteFullscreen, &_croixFullscreen {
                    position: absolute;
                    transform: translateY(-50%);
                    color: $white-color;
                    transition: all 0.4s;
                    cursor: pointer;
                    z-index: 100;
    
                    &:hover {
                        color: $primary-color;
                    }
                }
    
                &_chevronGaucheFullscreen {
                    left: 20px;
                }
    
                &_chevronDroiteFullscreen {
                    right: 20px;
                }
    
                &_croixFullscreen {
                    top: 75px;
                    right: 75px;
                }
            }

            &_photoAutre {
                @include display-flex(row);
                gap: 2%;
                padding-top: 10px;

                &_unePhoto{
                    width: 23.5%;
                    position: relative;
                    box-shadow: 0px 0px 15px 1px #00000050;
                    transition: all 0.4s;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.5;
                    }
                    
                    &::after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    & img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
    
            &_caracteristique {
                &_titre {
                    font-size: 20px;
                    font-weight: bold;
                    padding: 40px 0px;
                }

                &_bloc {
                    @include display-flex(column);
                    font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                    font-size: 17px;
                    gap: 40px;
                    padding-bottom: 20px;

                    &_ligne {        
                        @include display-flex(row);
                        justify-content: space-between;
                        padding-bottom: 20px;
    
                        & p:last-child {
                            text-align: right;
                        }
                
                        &:not(:last-child) {
                            border-bottom: 1px solid $black-color;
                        }
                    }
                }
            }
        }
    
        &_droite {
            height: auto;
            width: 55%;
            @include display-flex(column);
            gap: 30px;
            
            &_infos {
                @include display-flex(column);
                text-align: center;
                gap: 40px;

                &_blocUn {
                    @include display-flex(column);
                    gap: 8px;

                    &_nom {
                        font-size: 26px;
                        font-weight: bold;
                    }
        
                    &_description {
                        font-size: 12px;
                        text-align: center;
                        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                    }
                }

                &_blocDeux {
                    @include display-flex(column);
                    align-items: center;
                    gap: 10px;

                    &_prix {
                        width: 50%;
                        @include display-flex(column);
                        justify-content: center;
                        align-items: center;
                        gap: 8px;

                        &_haut {
                            @include display-flex(row);
                            align-items: baseline;
                            justify-content: left;
                            text-align: left;
                            gap: 5px;

                            &_montant {
                                font-size: 26px;
                                font-weight: bold;
                            }
    
                            &_devise {
                                font-size: 18px;
                            }
                        }

                        &_bas {
                            @include display-flex(row);
                            align-items: baseline;
                            justify-content: left;
                            text-align: left;
                            color: red;
                            text-decoration: line-through;
        
                            &_montant, &_devise {                                
                                font-size: 18px;
                            }
                            
                            &_offre {        
                                font-family: $font-family-secondary, $font-family-alternate, $font-family-default;                        
                                font-size: 16px;
                            }
                        }
                    }

                    &_stock {
                        height: 100%;
                        width: 50%;
                        @include display-flex(column);                        
                        align-items: center;
                        text-align: right;
                        gap: 5px;

                        &_haut {
                            font-size: 22px;
                            font-weight: bold;                            
                        }
    
                        &_bas {
                            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                            font-size: 12px;                            
                        }
                    }
                }
                
                &_xxl {
                    color: red;
                    font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                    font-size: 14px;
                }
            }
    
            &_achat {
                @include display-flex(column);
                gap: 30px;
    
                &_calcul {
                    @include display-flex(row);
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
    
                    &_container {
                        @include display-flex(column);
                        justify-content: center;
                        gap: 2px;

                        &_texteCalcul {
                            align-self: center;
                            font-size: 14px;
                        }
        
                        &_boutonCalcul {
                            width: fit-content;
                            @include display-flex(row);                            
                            align-items: center;
                            font-weight: bold;
                            background-color: $white-color;
                            padding: 5px 10px;
                            border: 1px solid $black-color;
                            border-radius: 40px;

                            &_boutonMoins, &_boutonPlus {
                                cursor: pointer;
                                padding: 10px;
                            }
    
                            & input {
                                width: 90px;
                                padding: 0px;
                                border: none;
                                background: none;
                                font-size: 26px;
                                font-weight: bold;
                                text-align: center;
                                outline: none;
                                cursor: text;
                            }
    
                            & input[type="number"] {
                                -webkit-appearance: textfield;
                                   -moz-appearance: textfield;
                                        appearance: textfield;
                            }
    
                            & input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                            }
                        }
                    }
                }
    
                &_prixCalcul {
                    @include display-flex(row);
                    justify-content: space-evenly;
                    font-size: 16px;
                    text-align: center;
                }

                &_boutonPanier, &_boutonEchantillon {
                    background-color: $primary-color;
                    color: $white-color;
                    border-radius: 30px;
                    transition: all 0.8s;

                    &:hover {
                        background-color: rgba($primary-color, 0.8);
                    }
                }

                &_boutonAchat {
                    height: 50px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-self: center;
                    background-color: $primary-color;
                    color: $white-color;
                    transition: all 0.8s;
                    cursor: pointer;

                    & .cart-button {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        overflow: hidden;
                        border-radius: 30px;

                        & .slide {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: -2500px;
                            bottom: 0;
                            background: $black-color;
                            padding: 10px 0px;
                            transition: all .35s ease-out;
                        }

                        &:hover .slide {
                            left: 0;
                        }
                    }
                }

                &_boutonPanier {
                    height: fit-content;
                    width: fit-content;
                    display: flex;
                    align-self: center;

                    & span {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        font-size: 16px;
                        padding: 5px 25px;
                    }
                }

                &_conseils {
                    width: fit-content;
                    @include display-flex(row);
                    justify-content: space-around;
                    align-self: center;
                    align-items: center;
                    gap: 14px;
                    border: 1px solid $black-color;
                    padding: 10px;

                    &_gauche {
                        display: flex;
                        
                        & img {
                            height: 30px;
                            width: 34px;
                        }
                    }

                    &_droite {
                        @include display-flex(column);
                        align-items: center;
                        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                        font-size: 14px;

                        & a {
                            text-decoration: underline;
                        }
                    }
                }

                &_boutonEchantillon {
                    height: fit-content;
                    width: fit-content;
                    display: flex;
                    
                    & a {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        font-size: 16px;
                        padding: 5px 25px;
                    }
                }
    
                &_echantillon {
                    @include display-flex(column);
                    gap: 10px;
                    padding-top: 30px;
                
                    &_titre {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    &_texte {
                        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                        font-size: 14px;
                    }
                }
            }
        }

        & .fixed {
            box-sizing: border-box;
            position: sticky;
            top: 105px;
            align-self: flex-start;
        }
    }
}

/* Animation bouton achat */ 
.article_droite_achat {
    &_boutonAchat {
        & .cart-button {
            width: 100%;
            position: relative;
            outline: 0;
            font-size: 22px;
            font-weight: bold;
            padding: 10px;
            overflow: hidden;

            & span {
                display: flex;
                height: 100%;
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                top: 50%;
                color: $white-color;
                transform: translate(-50%, -50%);

                &.added {
                    opacity: 0;
                }
            }

            &:focus {
                outline: none !important;
            }

            & .fa-shopping-cart {
                position: absolute;
                top: 50%;
                left: -20%;
                z-index: 2;
                color: $tertiary-color;
                font-size: 1.8em;
                transform: translate(-50%, -50%);
            }

            & .fa-square {
                position: absolute;
                top: -20%;
                left: 53%;
                z-index: 1;
                color: $tertiary-color;
                font-size: 0.8em;
                transform: translate(-100%, -90%);
            }
        }

        &.clicked {
            background-color: $primary-color !important;
    
            & .fa-shopping-cart {
                animation: cart 2s ease-in forwards;
            }
    
            & .fa-square {
                animation: box 2s ease-in forwards;
            }
    
            & span {
                &.add-to-cart {
                    animation: addcart 2s ease-in forwards;
                }
    
                &.added {
                    animation: added 2s ease-in forwards;
                }
            }
        }
    }

    &_boutonPanier.clicked {
        animation: shake 2s cubic-bezier(.36,.07,.19,.97) both;
        animation-iteration-count: 4;
        transform: translate3d(0, 0, 0);
    }
}

/* Animation sur le header */
.cartAnimation.clicked {
    animation: giggle 1s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: 15;
    transform: translate3d(0, 0, 0);
    color: red !important;
}

/* Grand Ecran */
@media all and (min-width: 1399px) {
    .articleMain {
        & .article_droite_achat_calcul_container_boutonCalcul input {    
            width: 80px;
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .articleMain {
        margin: 0px 65px;
    }
}

@media all and (max-width: 899px) {
    .articleMain {
        & .article {
            &_droite {
                &_achat {
                    &_calcul {
                        flex-direction: column !important;
                        text-align: center;
                        align-items: center;
                    }
            
                    &_container {
                        align-items: center;
                    }
                }
            }
        }
    }
}

/* Tablet */
@media all and (max-width: 767px) {
    .articleMain {
        margin: 0px 50px;

        & .navigationArticle {
            & .arianeArticle {    
                & ul {
                    font-size: 18px;
                }

                & li:not(:last-child)::after {
                    margin: 0px 8px;
                }
            }
        }

        & .article {
            flex-direction: column;
            align-items: center;

            &_gauche, &_droite {
                width: calc(100% - 40px);
            }

            &_gauche {
                &_blocPhoto {
                    padding: 0px 20px;
                }
                
                &_photoAutre {
                    padding: 10px 20px 0px 20px;
                }

                &_blocPhoto {
                    &_chevronGauche {
                        left: 25px;
                    }

                    &_chevronDroite {
                        right: 25px;
                    }
                }
            }

            &_droite {  
                align-self: center !important;
            }

            & .echantillon {
                padding-bottom: 0px;
            }

            & .fixed {
                position: relative;
                top: 0px;
                padding-bottom: 20px;
            }
        }

        
        & .arianeArticle {    
            & ul {
                display: flex;
                text-align: center;
                list-style: none;
                margin: 0px;
                padding: 0px;
                font-size: 20px;
            }
        }
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .articleMain {
        margin: 0px 25px;

        & .article {
            &_gauche, &_droite {
                width: calc(100% - 30px);
            }

            &_gauche {
                &_blocPhoto {
                    padding: 0px;
    
                    &_chevronGauche {
                        left: 5px;
                    } 
                    
                    &_chevronDroite {
                        right: 5px;
                    }
                }

                &_photoAutre {
                    padding: 10px 0px 0px 0px;
                }
            }
        }

        & .navigationArticle {
            padding: 10px;

            & ul {
                justify-content: center;
                font-size: 16px;
            }

            & li:not(:last-child)::after {
                margin: 0px 4px;
            }
        }
    }
}