@import "../../utils/Utils.scss";

/* Code */
.gammes {
    width: 100%;
    color: $tertiary-color;
    
    &_conteneur {
        width: 100%;
        @include display-flex(column);
        justify-content: center;
        align-items: center;
        text-shadow: 0px 0px 2px $black-color;
        padding-top: 100px;

        & h1 {
            text-align: center;
            color: $tertiary-color;
            font-size: 56px;
            letter-spacing: 5px;
            padding: 0px 20px;
            text-shadow: 0px 0px 3px $black-color;
        }

        & p {
            text-align: center;
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
            font-size: 22px;
            padding: 20px 50px;
            color: $tertiary-color;
        }

        &_ligne {
            width: 150px;
            align-self: center;
            border-bottom: 5px solid $tertiary-color;
            margin-top: 20px;
        }
    }

    &_contenu {
        &_premier, &_second {
            height: auto;
            width: 100%;
            @include display-flex(row);
            align-items: center;
            color: $black-color;
            gap: 50px;
        }

        &_premier {
            padding: 50px 0px;
        }

        // &_second {
        //     padding-top: 0px;
        // }

        &_hidden {
            display: none;
        }

        &_titre {
            height: 100%;
            width: 35%;
            display: flex;
            justify-content: right;
            align-items: center;
            text-align: right;
            font-size: 40px;
            font-weight: bold;
            padding-left: 10px;
        }

        &_texte {
            height: 100%;
            width: 65%;
            @include display-flex(column);
            justify-content: center;
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
            font-size: 18px;
            padding-right: 100px;
            gap: 8px;
        }
    }

    &_categories {
        width: 100%;
        @include display-flex(column);
        text-shadow: 0px 0px 2px $black-color;
        padding: 60px 0px;
        gap: 20px;

        &_card {
            height: 200px;
            width: 100%;
            overflow: hidden;
            
            & img {
                height: 100%;
                width: 100%;
                object-fit: cover; 
            }

            & a {
                height: 100%;
                width: 100%;
                display: flex;
                color: $tertiary-color;
                text-decoration: none;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s;
                background-position: center;
                background-size: cover;
        
                &:hover {
                    transform : scale(1.2);

                    & h2 {
                        color: $secondary-color;
                    }
                }
            }

            & h2 {
                display: flex;
                position: absolute;
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                letter-spacing: 5px;
                text-shadow: 2px 2px 2px $black-color;
            }

            &_sols-et-murs a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-sol-murs_opti.webp);
            }

            &_les-faïences-murales a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-faiences_opti.webp);
            }

            &_les-extérieurs a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-exterieurs_opti.webp);
            }

            &_les-terrazzos a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-terrazzo_opti.webp);
            }

            &_les-mosaïques a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-mosaiques_opti.webp);
            }
            
            &_accessoires-carrelage a {
                background-image: url(../../../assets_opti/gammes/gammes-carrelage-accessoires_opti.webp);
            }

            &_massif a {
                background-image: url(../../../assets_opti/gammes/gammes-parquet-massif_opti.webp);
            }

            &_contrecollé a {
                background-image: url(../../../assets_opti/gammes/gammes-parquet-contrecolle_opti.webp);
            }

            &_stratifié-et-pvc a {
                background-image: url(../../../assets_opti/gammes/gammes-parquet-stratifie_opti.webp);
            }

            &_lame-murale-polystyrène a {
                background-image: url(../../../assets_opti/gammes/gammes-parquet-lame_opti.webp);
            }

            &_accessoires-parquet a {
                background-image: url(../../../assets_opti/gammes/gammes-parquet-accessoires_opti.webp);
            }
        }
    }

    &_boutons {
        @include display-flex(row);
        justify-content: center;

        & .articlesEnStock, .articlesEnPromo {
            padding: 0px 40px;
        }
    }

    & ~ .aide {
        margin: 80px 50px;
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .gammes {
        &_conteneur h1 {
            font-size: 50px;
        }
        &_contenu {
            &_premier, &_second {
                gap: 35px;
            }

            &_titre {
                width: 30%;
            }

            &_texte {
                width: 70%;
                padding-right: 80px;
            }
        }
    }
}

/* Tablet */
@media all and (max-width: 767px) {
    .gammes {
        &_conteneur {
            & h1 {
                font-size: 40px;
            }

            & p {
                font-size: 16px;
            }
        }
        &_contenu {
            &_premier, &_second {
                gap: 15px;
            }

            &_titre {
                width: 25%;
                font-size: 30px;
            }

            &_texte {
                width: 75%;
                font-size: 14px;
                padding-right: 20px;
            }
        }

        &_categories_card {
            height: 150px;

            & h2 {
                font-size: 50px;
            }
        }

        & ~ .aide {
            margin: 80px 20px;
        }
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .gammes {
        &_conteneur {
            & h1 {
                font-size: 30px;
            }

            & p {
                font-size: 12px;
            }
        }
        
        &_contenu {
            &_premier, &_second {
                @include display-flex(column);
                gap: 0px;
                height: auto;
                width: 100%;
                padding: 20px 0px 40px 0px;
            }

            &_titre {
                width: 100%;
                font-size: 20px;
                justify-content: center;
                text-align: center;
                padding-bottom: 8px;
                padding-left: 0px;
            }

            &_texte {
                width: 95%;
                font-size: 11px;
                padding: 0px;
            }
        }

        &_categories {
            padding: 40px 0px 20px 0px;

            &_card h2 {
                font-size: 40px;
            }
        }

        & ~ .aide {
            margin: 50px 20px 40px 20px;
        }
    }
}