@import "../../utils/Utils.scss";

/* Code */ 
.panierMain {
    background-color: $tertiary-color;
    padding: 50px 100px;
}

.panier {
    width: 100%;
    @include display-flex(row);
    gap: 30px;

    &_gauche {
        width: 65%;
        @include display-flex(column);
        gap: 20px;

        &_titre {
            background-color: $tertiary-color;
            font-size: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid $black-color;
        }

        &_corps {
            @include display-flex(column);
            gap: 20px;

            &_card {
                @include display-flex(row);
                background-color: $white-color;
                box-shadow: $primary-color 0px 0px 10px;
                padding: 15px;
                gap: 20px;

                &_photo {
                    height: 150px;
                    width: 150px;
                }

                &_texte {
                    height: 100%;
                    width: 100%;
                    @include display-flex(column);
                    justify-content: space-between;
                    gap: 30px;

                    &_haut {
                        @include display-flex(row);
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 20px;

                        &_gauche {
                            @include display-flex(column);
                            gap: 5px;

                            &_nom {
                                font-size: 22px;
                                font-weight: bold;
                            }

                            &_tarif {
                                font-size: 16px;
                            }
                        }

                        &_droite {                            
                            @include display-flex(column);

                            &_ht {
                                font-size: 22px;
                                font-weight: bold;
                            }

                            &_ttc {
                                font-size: 14px;
                            }
                        }
                    }

                    &_bas {
                        @include display-flex(row);
                        justify-content: space-between;
                        align-items: flex-end;                        
                        flex-wrap: wrap;
                        gap: 10px;

                        &_boutonUn, &_boutonDeux {
                            @include display-flex(column);
                            gap: 5px;

                            &_label {
                                font-size: 14px;
                            }

                            &_conteneur {
                                @include display-flex(row);                            
                                align-items: center;
                                padding: 5px;
                                border: 1px solid $black-color;
                                border-radius: 20px;
                                gap: 10px;

                                &_boutonMoins, &_boutonPlus {
                                    cursor: pointer;
                                    padding: 5px;
                                }

                                & input {
                                    width: 90px;
                                    padding: 0px;
                                    border: none;
                                    background: none;
                                    font-size: 20px;
                                    font-weight: bold;
                                    text-align: center;
                                    outline: none;
                                    cursor: text;
                                }
        
                                & input[type="number"] {
                                    -webkit-appearance: textfield;
                                       -moz-appearance: textfield;
                                            appearance: textfield;
                                }
        
                                & input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                }
                            }
                        }

                        &_corbeille {
                            padding: 10px;
                        }
                    }
                }
            }
        }

        &_boutons {
            @include display-flex(row);
            justify-content: space-between;
            gap: 20px;
            cursor: pointer;

            &_continuer {
                @include display-flex(row);
                gap: 5px;
                font-size: 14px;
                align-items: center;

                & i {
                    align-self: center;
                }

                & p {
                    border-bottom: 1px solid $black-color;
                }
            }
        }
    }

    &_droite {
        height: fit-content;
        width: 35%;
        @include display-flex(column);
        box-sizing: border-box;
        position: sticky;
        top: 90px;
        gap: 20px;

        &_titre {
            font-size: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid $black-color;
        }

        &_corps {
            @include display-flex(column);
            gap: 20px;

            &_montants, &_paiements, &_engagements, &_question {
                @include display-flex(column);
                box-shadow: $primary-color 0px 0px 10px;
                background-color: #FFFFFF;
                padding: 20px;
                gap: 10px;

                & div {
                    @include display-flex(row);
                }

                &_titre {
                    font-weight: bold;
                    align-self: center;
                }

                &_valider {
                    margin-top: 10px;
                }
            }

            &_montants {
                gap: 20px;

                div {
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                &_livraison {
                    opacity: 0.5;
                }

                &_separateur {
                    border-bottom: 1px solid $black-color;
                }

                &_ttc {
                    font-weight: bold;
                }
            }

            &_paiements {              
                text-align: center;

                &_modes {
                    @include display-flex(row);
                    flex-wrap: wrap;
                    justify-content: center;                                  
                    align-content: center;
                    gap: 5px;
                    padding: 0px 20px;

                    & div {
                        height: 40px;
                        min-width: 50px;
                        width: 20%;
                        border: 1px solid $black-color;

                        & img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            &_engagements {
                &_titre {
                    text-align: center;
                }
                & div:not(:first-child) {
                    height: 30px;
                    align-items: center;               
                    text-align: center;
                    padding-left: 30px;
                    gap: 40px;
                }

                & span {
                    width: 35px;
                }
            }

            &_question {                             
                text-align: center;
            }
        }
    }

    &_valider {
        display: flex;
        align-self: center;
        text-align: center;
        border: 2px solid $black-color;
        border-radius: 20px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            color: $tertiary-color;
            background-color: $primary-color;
            border-color: $primary-color;
            transition: all 0.4s linear;
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .panierMain {
        font-size: 15px;
        padding: 25px 50px;
    }

    .panier {
        &_gauche {
            &_corps {
                &_card {
                    &_texte {
                        &_haut {
                            gap: 10px;
    
                            &_gauche {
                                gap: 0px;
                                &_nom {
                                    font-size: 20px;
                                }
    
                                &_tarif {
                                    font-size: 14px;
                                }
                            }
    
                            &_droite {
                                &_ht {
                                    font-size: 20px;
                                }
    
                                &_ttc {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &_droite {
            &_corps {
                &_engagements {
                    & div:not(:first-child) {
                        height: 50px;
                        padding-left: 0px;
                        gap: 10px;
                    }
    
                    & span {
                        width: 35px;
                    }
                }
            }
        }
    }
}

/* Tablet */
@media all and (max-width: 767px) { 
    .panierMain {
        font-size: 12px;
        padding: 10px 20px;
    }

    .panier {
        gap: 10px;

        &_gauche {            
            &_titre {
                font-size: 16px;
                padding-bottom: 10px;
            }

            &_corps {
                &_card {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &_texte {
                        &_haut {      
                            flex-direction: column;                      
                            justify-content: center;
                            text-align: center;
                            gap: 10px;
    
                            &_gauche {
                                gap: 0px;

                                &_nom {
                                    font-size: 16px;
                                }
    
                                &_tarif {
                                    font-size: 12px;
                                }
                            }
    
                            &_droite {
                                &_ht {
                                    font-size: 16px;
                                }
    
                                &_ttc {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &_droite {
            &_titre {
                font-size: 16px;
                padding-bottom: 10px;
            }
        }
    }
}