/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
.bodyMain {
  background-image: url(../assets_opti/autre/fond.webp);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainHome {
  margin: 0px 150px;
}
.mainHome .plus {
  margin: 60px -150px;
}

/* Gammes de Produits */
.gallery {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background: #FAFAFA;
  border: 0px solid #373a3c;
  box-shadow: rgba(55, 58, 60, 0.4) 0px 0px 15px;
  border-radius: 10px;
  margin: 60px -60px;
}
.gallery img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery_un, .gallery_six, .gallery_sept {
  text-shadow: 0px 0px 2px #28292A;
}
.gallery_un a, .gallery_six a, .gallery_sept a {
  transition: transform 0.5s;
}
.gallery_un a:hover, .gallery_six a:hover, .gallery_sept a:hover {
  transform: scale(1.15);
}
.gallery_un_titre, .gallery_six_titre, .gallery_sept_titre {
  font-size: 50px;
  font-weight: bold;
}
.gallery_titre_main, .gallery_titre_ten, .gallery_titre_san {
  text-align: center;
  padding: 20px 0px 20px 0px;
}
.gallery_titre_main h2, .gallery_titre_ten h2, .gallery_titre_san h2 {
  font-size: 50px;
  font-weight: bold;
}
.gallery_titre_main h3, .gallery_titre_ten h3, .gallery_titre_san h3 {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 28px;
}
.gallery_car, .gallery_par, .gallery_san, .gallery_ten {
  height: 250px;
}
.gallery_car h4, .gallery_par h4, .gallery_san h4, .gallery_ten h4 {
  position: absolute;
  text-align: center;
  font-family: "Jost", "Lato", sans-serif;
  letter-spacing: 0.8px;
}
.gallery_car a, .gallery_par a, .gallery_san a, .gallery_ten a {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FAFAFA;
}
.gallery_car a:hover h3, .gallery_car a:hover h4, .gallery_par a:hover h3, .gallery_par a:hover h4, .gallery_san a:hover h3, .gallery_san a:hover h4, .gallery_ten a:hover h3, .gallery_ten a:hover h4 {
  color: #D8D8D8;
}
.gallery_ten {
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
  gap: 1%;
}
.gallery_un {
  display: flex;
  flex-direction: row;
  gap: 1%;
}
.gallery_deux {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 46px;
  gap: 2%;
}
.gallery_trois {
  height: 50%;
  display: flex;
  flex-direction: row;
  gap: 1.5%;
}
.gallery_quatre {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 32px;
}
.gallery_cinq {
  height: 100%;
  width: 33.33%;
  display: flex;
  font-size: 40px;
}
.gallery_six {
  height: 100%;
  width: 33.33%;
  display: flex;
  font-size: 54px;
}
.gallery_sept {
  height: 100%;
  width: 66.66%;
  display: flex;
  flex-direction: column;
  gap: 1.5%;
}
.gallery_huit {
  height: 50%;
  width: 100%;
  display: flex;
  font-size: 54px;
  gap: 1.5%;
}
.gallery_neuf {
  height: 100%;
  width: 50%;
  display: flex;
  font-size: 46px;
}
.gallery_un, .gallery_deux, .gallery_trois, .gallery_quatre, .gallery_cinq, .gallery_six, .gallery_sept, .gallery_huit, .gallery_neuf {
  overflow: hidden;
  border-radius: 10px;
}
.gallery_trois, .gallery_cinq {
  position: relative;
}

/* Mis en Avant */
.misEnAvant {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
.misEnAvant h3 {
  display: flex;
  font-size: 28px;
  font-weight: bold;
}
.misEnAvant_titre {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.misEnAvant_titre button {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: #28292A;
  border: none;
  transition: transform 0.5s;
  cursor: pointer;
}
.misEnAvant_titre button:hover {
  transform: scale(1.07);
}
.misEnAvant_titre_prix, .misEnAvant_titre_top {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.misEnAvant_titre_prix .titre-actif h3, .misEnAvant_titre_top .titre-actif h3 {
  font-weight: bold;
  color: #373a3c;
  transform: scale(1.07);
}
.misEnAvant_titre_prix .titre-actif ~ .misEnAvant_titre_ligne, .misEnAvant_titre_top .titre-actif ~ .misEnAvant_titre_ligne {
  display: flex;
  border-bottom: 3px solid #373a3c;
}
.misEnAvant_titre_ligne {
  display: none;
  width: 100%;
  padding-top: 20px;
}
.misEnAvant_bloc {
  display: none;
}
.misEnAvant_bloc_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin: 0px -20px;
}
.misEnAvant_bloc_fleche {
  align-self: center;
  padding: 10px 5px;
  margin-bottom: 120px;
}
.misEnAvant_bloc_fleche#fleche_gauche_promo, .misEnAvant_bloc_fleche#fleche_gauche_stock {
  color: #FAFAFA;
}
.misEnAvant_bloc_fleche#fleche_droite_promo, .misEnAvant_bloc_fleche#fleche_droite_stock {
  cursor: pointer;
}
.misEnAvant_bloc_fige {
  width: 620px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-top: 40px;
}
.misEnAvant_bloc_article {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.misEnAvant_bloc_article_prix {
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.misEnAvant_bloc_article_un, .misEnAvant_bloc_article_deux, .misEnAvant_bloc_article_trois, .misEnAvant_bloc_article_quatre {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 20px;
}
.misEnAvant_bloc_article_un .banniereTop, .misEnAvant_bloc_article_deux .banniereTop, .misEnAvant_bloc_article_trois .banniereTop, .misEnAvant_bloc_article_quatre .banniereTop {
  padding: 5px 43px;
  position: absolute;
  top: 35px;
  left: -40px;
  font-size: 13px;
  background-color: #373a3c;
  color: #FFFFFF;
  transform: rotate(-45deg);
}
.misEnAvant_bloc_article_photo {
  display: flex;
  width: 100%;
  position: relative;
  color: #28292A;
}
.misEnAvant_bloc_article_photo::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.misEnAvant_bloc_article_photo:hover .misEnAvant_bloc_article_photo_un {
  display: none;
}
.misEnAvant_bloc_article_photo:hover .misEnAvant_bloc_article_photo_deux {
  display: flex;
}
.misEnAvant_bloc_article_photo img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.misEnAvant_bloc_article_photo_deux {
  display: none;
}
.misEnAvant_bloc_article_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 20px;
}
.misEnAvant_bloc_article_info_nom {
  font-size: 16px;
}
.misEnAvant_bloc_article_info_nom:hover {
  color: #373a3c;
}
.misEnAvant_bloc_article_info_cat {
  height: 30px;
  font-size: 14px;
}
.misEnAvant_bloc_article_info_prix {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 3%;
  color: #373a3c;
}
.misEnAvant_bloc_article_info_prix_gauche {
  font-size: 20px;
  font-weight: bold;
}
.misEnAvant_bloc_article_info_prix_gauche_offre {
  font-size: 20px;
  font-weight: bold;
  color: red;
}
.misEnAvant_bloc_article_info_prix_gauche_remise {
  font-size: 20px;
  font-weight: bold;
}
.misEnAvant_bloc_article_info_prix_gauche_avant {
  font-size: 16px;
  text-decoration: line-through;
  color: red;
}
.misEnAvant_bloc_article_info_prix_droite {
  font-size: 14px;
}
.misEnAvant_bloc_article_info_prix_droite_offre {
  font-size: 16px;
  font-weight: bold;
  color: red;
}
.misEnAvant_bloc_article_info_prix_droite_remise {
  font-size: 16px;
  font-weight: bold;
}
.misEnAvant_bloc_article_info_prix_droite_avant {
  font-size: 14px;
  text-decoration: line-through;
  color: red;
}
.misEnAvant_bloc_article_info_prix_barre {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 3%;
  font-size: 16px;
  color: red;
  text-decoration: line-through;
}
.misEnAvant_bloc_article_info_prix_barre_gauche {
  font-size: 16px;
  font-weight: bold;
}
.misEnAvant_bloc_article_info_prix_barre_droite {
  font-size: 12px;
}
.misEnAvant_bloc_bouton {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.misEnAvant_bloc_bouton_un {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #373a3c;
  color: #FAFAFA;
  font-weight: bold;
  cursor: pointer;
}
.misEnAvant_bloc_bouton_un:hover {
  background-color: red;
  transition: all 0.4s linear;
}
.misEnAvant_bloc.show-bloc {
  display: flex;
  flex-direction: column;
}

/* Sections Presentation / Accompagnement / Pro */
.presentation, .accompagnement, .pro {
  height: auto;
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  gap: 20px;
}
.presentation_bloc, .accompagnement_bloc, .pro_bloc {
  height: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 10px;
  gap: 20px;
}
.presentation_bloc h1, .presentation_bloc p, .accompagnement_bloc h1, .accompagnement_bloc p, .pro_bloc h1, .pro_bloc p {
  font-size: 18px;
}
.presentation_bloc h2, .accompagnement_bloc h2, .pro_bloc h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
}
.presentation_bloc_titre, .presentation_bloc_texte, .accompagnement_bloc_titre, .accompagnement_bloc_texte, .pro_bloc_titre, .pro_bloc_texte {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.presentation_bloc_titre_ligne, .presentation_bloc_texte_ligne, .accompagnement_bloc_titre_ligne, .accompagnement_bloc_texte_ligne, .pro_bloc_titre_ligne, .pro_bloc_texte_ligne {
  width: 150px;
  align-self: center;
  border-bottom: 3px solid #28292A;
}
.presentation_photo, .accompagnement_photo, .pro_photo {
  height: auto;
  max-height: 700px;
  width: 60%;
  display: flex;
}
.presentation_photo img, .accompagnement_photo img, .pro_photo img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.presentation_bloc_texte, .pro_bloc_texte, .accompagnement_bloc_texte {
  font-family: "Jost", "Lato", sans-serif !important;
  text-align: center;
}

.pro {
  padding-bottom: 60px;
}

.boutons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.bouton_accompagnement, .bouton_pro {
  display: flex;
  align-self: center;
}
.bouton_accompagnement a, .bouton_pro a {
  font-weight: bold;
  font-size: 20px;
  border: 2px solid #28292A;
  border-radius: 20px;
  padding: 8px 16px;
}
.bouton_accompagnement a:hover, .bouton_pro a:hover {
  color: #FAFAFA;
  background-color: #373a3c;
  border-color: #373a3c;
  transition: all 0.6s linear;
}

/* Showroom */
.showroom {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.showroom_titre {
  font-size: 46px;
  font-weight: bold;
}
.showroom_bloc {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.showroom_bloc_deux {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 20px;
}
.showroom_bloc_deux img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
  cursor: zoom-in;
}
.showroom_bloc_deux img:hover {
  opacity: 0.7;
}
.showroom_bloc_deux a:hover {
  text-decoration: underline;
}
.showroom_bloc_deux_texte {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Jost", "Lato", sans-serif;
  gap: 10px;
}
.showroom_bloc_deux_texte h3 {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 32px;
  color: #373a3c;
}
.showroom_bloc_deux_texte a, .showroom_bloc_deux_texte p {
  font-size: 20px;
}
.showroom_bloc_deux_fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.showroom_bloc_deux_fullscreen img {
  max-width: 90%;
  max-height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: zoom-out;
}
.showroom_bloc_deux_fullscreen_croixFullscreen {
  position: absolute;
  top: 50px;
  right: 50px;
  transform: translateY(-50%);
  color: #FFFFFF;
  transition: all 0.4s;
  cursor: pointer;
  z-index: 100;
}
.showroom_bloc_deux_fullscreen_croixFullscreen:hover {
  color: #373a3c;
}
.showroom_adresse {
  font-weight: bold;
  font-style: italic;
}

.articlesEnStock, .articlesEnPromo {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

/* Grand Ecran */
@media all and (min-width: 1240px) {
  .misEnAvant_bloc_fige {
    width: 940px;
  }
}
@media all and (min-width: 1560px) {
  .misEnAvant_bloc_fige {
    width: 1260px;
  }
}
@media all and (min-width: 1880px) {
  .misEnAvant_bloc_fige {
    width: 1580px;
  }
}
@media all and (min-width: 2200px) {
  .misEnAvant_bloc_fige {
    width: 1900px;
  }
}
@media all and (min-width: 1399px) {
  .presentation_bloc, .pro_bloc, .accompagnement_bloc {
    padding: 30px 0px 30px 50px;
  }
}
/* Petit Ecran */
@media all and (max-width: 1199px) {
  .mainHome {
    margin: 0px 100px;
  }
  .mainHome .plus {
    margin: 50px -100px;
  }
  .gallery_titre_main h2, .gallery_titre_ten h2, .gallery_titre_san h2 {
    font-size: 46px;
  }
  .gallery_titre_main h3, .gallery_titre_ten h3, .gallery_titre_san h3 {
    font-size: 24px;
  }
  .gallery_deux {
    font-size: 40px;
  }
  .gallery_quatre {
    font-size: 28px;
  }
  .gallery_cinq {
    font-size: 36px;
  }
  .misEnAvant h3 {
    font-size: 22px;
  }
  .misEnAvant_bloc_article_un, .misEnAvant_bloc_article_deux, .misEnAvant_bloc_article_trois {
    width: 33.33%;
  }
  .misEnAvant_bloc_article_quatre {
    display: none;
  }
  .misEnAvant_bloc_article_info {
    font-size: 18px;
  }
  .misEnAvant_bloc_article_info_prix_barre {
    font-size: 14px;
  }
  .presentation_bloc h2, .accompagnement_bloc h2, .pro_bloc h2 {
    font-size: 26px;
  }
  .presentation_bloc h1, .presentation_bloc p, .accompagnement_bloc h1, .accompagnement_bloc p, .pro_bloc h1, .pro_bloc p {
    font-size: 16px;
  }
  .bouton_accompagnement a, .bouton_pro a {
    font-size: 18px;
  }
  .showroom_titre {
    font-size: 40px;
  }
  .showroom_bloc_deux_texte h3 {
    font-size: 26px;
  }
  .showroom_bloc_deux_texte a, .showroom_bloc_deux_texte p {
    font-size: 18px;
  }
}
/* Tablet */
@media all and (max-width: 820px) {
  .misEnAvant_bloc_fige {
    width: 300px;
  }
}
@media all and (max-width: 767px) {
  /* Smartphone */
  html, body {
    background-image: none;
  }
  .mainHome {
    margin: 0px 75px;
  }
  .mainHome .plus {
    margin: 60px -75px;
  }
  .gallery_titre_main h2, .gallery_titre_ten h2, .gallery_titre_san h2 {
    font-size: 36px;
  }
  .gallery_titre_main h3, .gallery_titre_ten h3, .gallery_titre_san h3 {
    font-size: 18px;
  }
  .gallery_car {
    flex-direction: column;
  }
  .gallery_par {
    flex-direction: column-reverse;
  }
  .gallery_ten {
    flex-direction: column;
  }
  .gallery_ten .gallery_six, .gallery_ten .gallery_sept {
    width: 100%;
  }
  .gallery_san {
    flex-direction: column;
    width: 100%;
  }
  .gallery_san .gallery_cinq {
    width: 100%;
  }
  .gallery_car .gallery_deux, .gallery_par .gallery_deux {
    height: 50%;
    width: 100%;
  }
  .gallery_un {
    gap: 4%;
  }
  .gallery_deux {
    font-size: 30px;
  }
  .gallery_quatre {
    font-size: 22px;
  }
  .gallery_cinq {
    font-size: 26px;
  }
  .gallery_six {
    font-size: 50px;
  }
  .gallery_huit {
    font-size: 42px;
  }
  .gallery_neuf {
    font-size: 36px;
  }
  .misEnAvant h3 {
    font-size: 18px;
  }
  .misEnAvant_bloc_article_un, .misEnAvant_bloc_article_deux {
    width: 50%;
  }
  .misEnAvant_bloc_article_trois, .misEnAvant_bloc_article_quatre {
    display: none;
  }
  .presentation, .accompagnement, .pro {
    gap: 10px;
    padding-top: 30px;
    /*&_promo {
        bottom: 10px;
        left: 10px;

        & h2 {
            font-size: calc(1.4em + 1.4vw);
            font-weight: bolder;
        }

        & h3 {
            font-size: calc(1em + 1vw);
            font-weight: bold;
        }
    }*/
  }
  .presentation_photo, .accompagnement_photo, .pro_photo {
    width: 40%;
  }
  .presentation_bloc, .accompagnement_bloc, .pro_bloc {
    width: 60%;
  }
  .presentation_bloc h2, .accompagnement_bloc h2, .pro_bloc h2 {
    font-size: 22px;
  }
  .presentation_bloc h1, .presentation_bloc p, .accompagnement_bloc h1, .accompagnement_bloc p, .pro_bloc h1, .pro_bloc p {
    font-size: 12px;
  }
  .pro {
    padding-bottom: 30px;
  }
  .gallery {
    padding: 10px;
    margin-top: 90px;
  }
  .misEnAvant {
    padding: 30px 10px 0px 10px;
  }
  .misEnAvant_bloc {
    padding-top: 20px;
  }
  .showroom {
    margin-bottom: 30px;
  }
  .showroom_titre {
    font-size: 32px;
  }
  .showroom_bloc {
    display: flex;
    flex-direction: column;
  }
  .showroom_bloc_deux {
    width: 100%;
    gap: 10px;
  }
  .showroom_bloc_deux img {
    height: 250px;
  }
  .showroom_bloc_deux_texte {
    gap: 0px;
  }
  .showroom_bloc_deux_texte h3 {
    font-size: 22px;
  }
  .showroom_bloc_deux_texte a, .showroom_bloc_deux_texte p {
    font-size: 16px;
  }
}
/* Smartphone */
@media all and (max-width: 549px) {
  .bodyMain {
    background-image: none;
  }
  .mainHome {
    margin: 0px 30px;
  }
  .mainHome .plus {
    margin: 30px -40px;
  }
  .gallery {
    margin: 60px 10px 30px 10px;
  }
  .gallery_titre_main h2, .gallery_titre_main h4, .gallery_titre_ten h2, .gallery_titre_ten h4, .gallery_titre_san h2, .gallery_titre_san h4 {
    font-size: 30px;
  }
  .gallery_titre_main {
    padding: 10px 0px 0px 0px;
  }
  .gallery_titre_san {
    padding: 0px;
  }
  .gallery_un_titre {
    font-size: 40px;
  }
  .gallery_deux {
    font-size: 26px;
  }
  .gallery_quatre {
    font-size: 20px;
  }
  .gallery_cinq {
    font-size: 23px;
  }
  .gallery_six {
    font-size: 46px;
  }
  .gallery_huit {
    font-size: 36px;
  }
  .gallery_neuf {
    font-size: 28px;
  }
  .misEnAvant {
    padding: 30px 0px 0px 0px;
  }
  .misEnAvant_bloc {
    width: calc(100% + 40px);
    gap: 5px;
  }
  .misEnAvant_bloc_article_un, .misEnAvant_bloc_article_deux {
    width: 50%;
  }
  .misEnAvant_bloc_article_trois, .misEnAvant_bloc_article_quatre {
    display: none;
  }
  .misEnAvant_bloc_article_info {
    font-size: 16px;
  }
  .misEnAvant_bloc_article_info_prix_barre {
    font-size: 12px;
  }
  .presentation_bloc_titre, .pro_bloc_titre, .accompagnement_bloc_titre {
    display: none;
  }
  /*.presentation_promo {
      & h2 {
          font-size: calc(1.2em + 1.2vw);
      }

      & h3 {
          font-size: calc(0.9em + 0.9vw);
      }
  }*/
  .showroom {
    padding: 0px 30px;
  }
  .showroom_titre {
    text-align: center;
    font-size: 26px;
  }
  .showroom_bloc_texte {
    font-size: calc(1em + 1vw);
  }
  .showroom_bloc_deux_texte h3 {
    font-size: 20px;
  }
  .showroom_bloc_deux_texte a, .showroom_bloc_deux_texte p {
    font-size: 14px;
  }
  .showroom_bloc_deux img {
    height: 200px;
  }
}
@media all and (max-width: 449px) {
  .presentation_bloc h1, .presentation_bloc p, .accompagnement_bloc h1, .accompagnement_bloc p, .pro_bloc h1, .pro_bloc p {
    font-size: 12px;
  }
  .bouton_accompagnement a, .bouton_pro a {
    font-size: 14px;
  }
}/*# sourceMappingURL=home.css.map */