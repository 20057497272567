/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
.articleMain {
  display: flex;
  flex-direction: column;
  margin: 0px 100px;
}
.articleMain .navigationArticle {
  padding: 10px 0px 20px 0px;
  /* &_conteneur {
      height: 100%;
      width: 100%;
      @include display-flex(column);
      align-self: center;
      gap: 30px;
  } */
}
.articleMain .navigationArticle ul {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  padding: 0px;
  font-size: 18px;
  line-height: 2;
}
.articleMain .navigationArticle li {
  display: flex;
}
.articleMain .navigationArticle li:not(:last-child) a, .articleMain .navigationArticle li:not(:last-child) span {
  color: #373a3c;
  font-weight: bolder;
}
.articleMain .navigationArticle li:not(:last-child)::after {
  display: inline-block;
  margin: 0px 16px;
  content: ">";
}
.articleMain .article {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10%;
}
.articleMain .article_buyBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.articleMain .article_buyBox_display {
  display: flex;
  margin: 0px 50px;
  padding: 20px;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
}
.articleMain .article_buyBox_display_texte {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.articleMain .article_buyBox_cross {
  position: absolute;
  top: 30%;
  right: 5%;
  color: #FFFFFF;
  transition: all 0.4s;
  cursor: pointer;
  z-index: 100;
}
.articleMain .article_gauche {
  width: 45%;
}
.articleMain .article_gauche_blocPhoto {
  position: relative;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  transition: all 0.4s;
  cursor: zoom-in;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale:hover {
  opacity: 0.5;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale_fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.articleMain .article_gauche_blocPhoto_photoPrincipale_fullscreen img {
  max-width: 90%;
  max-height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: zoom-out;
}
.articleMain .article_gauche_blocPhoto_chevronGauche, .articleMain .article_gauche_blocPhoto_chevronDroite {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 5px;
  color: rgba(40, 41, 42, 0.8);
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.4s;
  cursor: pointer;
}
.articleMain .article_gauche_blocPhoto_chevronGauche:hover, .articleMain .article_gauche_blocPhoto_chevronDroite:hover {
  color: #28292a;
  background-color: white;
}
.articleMain .article_gauche_blocPhoto_chevronGauche {
  left: 5px;
}
.articleMain .article_gauche_blocPhoto_chevronDroite {
  right: 5px;
}
.articleMain .article_gauche_blocPhoto_chevronGaucheFullscreen, .articleMain .article_gauche_blocPhoto_chevronDroiteFullscreen, .articleMain .article_gauche_blocPhoto_croixFullscreen {
  position: absolute;
  transform: translateY(-50%);
  color: #FFFFFF;
  transition: all 0.4s;
  cursor: pointer;
  z-index: 100;
}
.articleMain .article_gauche_blocPhoto_chevronGaucheFullscreen:hover, .articleMain .article_gauche_blocPhoto_chevronDroiteFullscreen:hover, .articleMain .article_gauche_blocPhoto_croixFullscreen:hover {
  color: #373a3c;
}
.articleMain .article_gauche_blocPhoto_chevronGaucheFullscreen {
  left: 20px;
}
.articleMain .article_gauche_blocPhoto_chevronDroiteFullscreen {
  right: 20px;
}
.articleMain .article_gauche_blocPhoto_croixFullscreen {
  top: 75px;
  right: 75px;
}
.articleMain .article_gauche_photoAutre {
  display: flex;
  flex-direction: row;
  gap: 2%;
  padding-top: 10px;
}
.articleMain .article_gauche_photoAutre_unePhoto {
  width: 23.5%;
  position: relative;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  transition: all 0.4s;
  cursor: pointer;
}
.articleMain .article_gauche_photoAutre_unePhoto:hover {
  opacity: 0.5;
}
.articleMain .article_gauche_photoAutre_unePhoto::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.articleMain .article_gauche_photoAutre_unePhoto img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.articleMain .article_gauche_caracteristique_titre {
  font-size: 20px;
  font-weight: bold;
  padding: 40px 0px;
}
.articleMain .article_gauche_caracteristique_bloc {
  display: flex;
  flex-direction: column;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 17px;
  gap: 40px;
  padding-bottom: 20px;
}
.articleMain .article_gauche_caracteristique_bloc_ligne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.articleMain .article_gauche_caracteristique_bloc_ligne p:last-child {
  text-align: right;
}
.articleMain .article_gauche_caracteristique_bloc_ligne:not(:last-child) {
  border-bottom: 1px solid #28292A;
}
.articleMain .article_droite {
  height: auto;
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.articleMain .article_droite_infos {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 40px;
}
.articleMain .article_droite_infos_blocUn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.articleMain .article_droite_infos_blocUn_nom {
  font-size: 26px;
  font-weight: bold;
}
.articleMain .article_droite_infos_blocUn_description {
  font-size: 12px;
  text-align: center;
  font-family: "Jost", "Lato", sans-serif;
}
.articleMain .article_droite_infos_blocDeux {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.articleMain .article_droite_infos_blocDeux_prix {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.articleMain .article_droite_infos_blocDeux_prix_haut {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: left;
  text-align: left;
  gap: 5px;
}
.articleMain .article_droite_infos_blocDeux_prix_haut_montant {
  font-size: 26px;
  font-weight: bold;
}
.articleMain .article_droite_infos_blocDeux_prix_haut_devise {
  font-size: 18px;
}
.articleMain .article_droite_infos_blocDeux_prix_bas {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: left;
  text-align: left;
  color: red;
  text-decoration: line-through;
}
.articleMain .article_droite_infos_blocDeux_prix_bas_montant, .articleMain .article_droite_infos_blocDeux_prix_bas_devise {
  font-size: 18px;
}
.articleMain .article_droite_infos_blocDeux_prix_bas_offre {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 16px;
}
.articleMain .article_droite_infos_blocDeux_stock {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  gap: 5px;
}
.articleMain .article_droite_infos_blocDeux_stock_haut {
  font-size: 22px;
  font-weight: bold;
}
.articleMain .article_droite_infos_blocDeux_stock_bas {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 12px;
}
.articleMain .article_droite_infos_xxl {
  color: red;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 14px;
}
.articleMain .article_droite_achat {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.articleMain .article_droite_achat_calcul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-family: "Jost", "Lato", sans-serif;
}
.articleMain .article_droite_achat_calcul_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}
.articleMain .article_droite_achat_calcul_container_texteCalcul {
  align-self: center;
  font-size: 14px;
}
.articleMain .article_droite_achat_calcul_container_boutonCalcul {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  background-color: #FFFFFF;
  padding: 5px 10px;
  border: 1px solid #28292A;
  border-radius: 40px;
}
.articleMain .article_droite_achat_calcul_container_boutonCalcul_boutonMoins, .articleMain .article_droite_achat_calcul_container_boutonCalcul_boutonPlus {
  cursor: pointer;
  padding: 10px;
}
.articleMain .article_droite_achat_calcul_container_boutonCalcul input {
  width: 90px;
  padding: 0px;
  border: none;
  background: none;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  outline: none;
  cursor: text;
}
.articleMain .article_droite_achat_calcul_container_boutonCalcul input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.articleMain .article_droite_achat_calcul_container_boutonCalcul input[type=number]::-webkit-inner-spin-button, .articleMain .article_droite_achat_calcul_container_boutonCalcul input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.articleMain .article_droite_achat_prixCalcul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  text-align: center;
}
.articleMain .article_droite_achat_boutonPanier, .articleMain .article_droite_achat_boutonEchantillon {
  background-color: #373a3c;
  color: #FFFFFF;
  border-radius: 30px;
  transition: all 0.8s;
}
.articleMain .article_droite_achat_boutonPanier:hover, .articleMain .article_droite_achat_boutonEchantillon:hover {
  background-color: rgba(55, 58, 60, 0.8);
}
.articleMain .article_droite_achat_boutonAchat {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: #373a3c;
  color: #FFFFFF;
  transition: all 0.8s;
  cursor: pointer;
}
.articleMain .article_droite_achat_boutonAchat .cart-button {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 30px;
}
.articleMain .article_droite_achat_boutonAchat .cart-button .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -2500px;
  bottom: 0;
  background: #28292A;
  padding: 10px 0px;
  transition: all 0.35s ease-out;
}
.articleMain .article_droite_achat_boutonAchat .cart-button:hover .slide {
  left: 0;
}
.articleMain .article_droite_achat_boutonPanier {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-self: center;
}
.articleMain .article_droite_achat_boutonPanier span {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 5px 25px;
}
.articleMain .article_droite_achat_conseils {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  gap: 14px;
  border: 1px solid #28292A;
  padding: 10px;
}
.articleMain .article_droite_achat_conseils_gauche {
  display: flex;
}
.articleMain .article_droite_achat_conseils_gauche img {
  height: 30px;
  width: 34px;
}
.articleMain .article_droite_achat_conseils_droite {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 14px;
}
.articleMain .article_droite_achat_conseils_droite a {
  text-decoration: underline;
}
.articleMain .article_droite_achat_boutonEchantillon {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}
.articleMain .article_droite_achat_boutonEchantillon a {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 5px 25px;
}
.articleMain .article_droite_achat_echantillon {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;
}
.articleMain .article_droite_achat_echantillon_titre {
  font-size: 16px;
  font-weight: bold;
}
.articleMain .article_droite_achat_echantillon_texte {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 14px;
}
.articleMain .article .fixed {
  box-sizing: border-box;
  position: sticky;
  top: 105px;
  align-self: flex-start;
}

/* Animation bouton achat */
.article_droite_achat_boutonAchat .cart-button {
  width: 100%;
  position: relative;
  outline: 0;
  font-size: 22px;
  font-weight: bold;
  padding: 10px;
  overflow: hidden;
}
.article_droite_achat_boutonAchat .cart-button span {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #FFFFFF;
  transform: translate(-50%, -50%);
}
.article_droite_achat_boutonAchat .cart-button span.added {
  opacity: 0;
}
.article_droite_achat_boutonAchat .cart-button:focus {
  outline: none !important;
}
.article_droite_achat_boutonAchat .cart-button .fa-shopping-cart {
  position: absolute;
  top: 50%;
  left: -20%;
  z-index: 2;
  color: #FAFAFA;
  font-size: 1.8em;
  transform: translate(-50%, -50%);
}
.article_droite_achat_boutonAchat .cart-button .fa-square {
  position: absolute;
  top: -20%;
  left: 53%;
  z-index: 1;
  color: #FAFAFA;
  font-size: 0.8em;
  transform: translate(-100%, -90%);
}
.article_droite_achat_boutonAchat.clicked {
  background-color: #373a3c !important;
}
.article_droite_achat_boutonAchat.clicked .fa-shopping-cart {
  animation: cart 2s ease-in forwards;
}
.article_droite_achat_boutonAchat.clicked .fa-square {
  animation: box 2s ease-in forwards;
}
.article_droite_achat_boutonAchat.clicked span.add-to-cart {
  animation: addcart 2s ease-in forwards;
}
.article_droite_achat_boutonAchat.clicked span.added {
  animation: added 2s ease-in forwards;
}
.article_droite_achat_boutonPanier.clicked {
  animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 4;
  transform: translate3d(0, 0, 0);
}

/* Animation sur le header */
.cartAnimation.clicked {
  animation: giggle 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 15;
  transform: translate3d(0, 0, 0);
  color: red !important;
}

/* Grand Ecran */
@media all and (min-width: 1399px) {
  .articleMain .article_droite_achat_calcul_container_boutonCalcul input {
    width: 80px;
  }
}
/* Petit Ecran */
@media all and (max-width: 1199px) {
  .articleMain {
    margin: 0px 65px;
  }
}
@media all and (max-width: 899px) {
  .articleMain .article_droite_achat_calcul {
    flex-direction: column !important;
    text-align: center;
    align-items: center;
  }
  .articleMain .article_droite_achat_container {
    align-items: center;
  }
}
/* Tablet */
@media all and (max-width: 767px) {
  .articleMain {
    margin: 0px 50px;
  }
  .articleMain .navigationArticle .arianeArticle ul {
    font-size: 18px;
  }
  .articleMain .navigationArticle .arianeArticle li:not(:last-child)::after {
    margin: 0px 8px;
  }
  .articleMain .article {
    flex-direction: column;
    align-items: center;
  }
  .articleMain .article_gauche, .articleMain .article_droite {
    width: calc(100% - 40px);
  }
  .articleMain .article_gauche_blocPhoto {
    padding: 0px 20px;
  }
  .articleMain .article_gauche_photoAutre {
    padding: 10px 20px 0px 20px;
  }
  .articleMain .article_gauche_blocPhoto_chevronGauche {
    left: 25px;
  }
  .articleMain .article_gauche_blocPhoto_chevronDroite {
    right: 25px;
  }
  .articleMain .article_droite {
    align-self: center !important;
  }
  .articleMain .article .echantillon {
    padding-bottom: 0px;
  }
  .articleMain .article .fixed {
    position: relative;
    top: 0px;
    padding-bottom: 20px;
  }
  .articleMain .arianeArticle ul {
    display: flex;
    text-align: center;
    list-style: none;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }
}
/* Smartphone */
@media all and (max-width: 549px) {
  .articleMain {
    margin: 0px 25px;
  }
  .articleMain .article_gauche, .articleMain .article_droite {
    width: calc(100% - 30px);
  }
  .articleMain .article_gauche_blocPhoto {
    padding: 0px;
  }
  .articleMain .article_gauche_blocPhoto_chevronGauche {
    left: 5px;
  }
  .articleMain .article_gauche_blocPhoto_chevronDroite {
    right: 5px;
  }
  .articleMain .article_gauche_photoAutre {
    padding: 10px 0px 0px 0px;
  }
  .articleMain .navigationArticle {
    padding: 10px;
  }
  .articleMain .navigationArticle ul {
    justify-content: center;
    font-size: 16px;
  }
  .articleMain .navigationArticle li:not(:last-child)::after {
    margin: 0px 4px;
  }
}/*# sourceMappingURL=Article.css.map */