@import "../utils/Utils.scss";

/* Code */
/* Bouton "Remonter en Haut de Page" */
.boutonScrollToTop {
    height: 70px;
    width: 70px;
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: $white-color;
    border: 1px solid #00000020;
    border-radius: 70px;
    transition: all 1s;
    z-index: 10;

    &:hover {
        background-color: $primary-color;

        & .fa-chevron-up > path {
            color: $white-color;
            transition: all 1s;
        }
    }

    & button {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .fa-chevron-up > path {
        color: $primary-color;
    }
}

/* Loading-spinner */
.spinner {
    display: flex;
    position: absolute;

    & div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;

        &:after {
            content: " ";
            width: 4px;
            height: 12px;
            display: block;
            position: absolute;
            border-radius: 20%;
            background: $black-color;
        }

        &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }

        &:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }

        &:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }

        &:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }

        &:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }

        &:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }

        &:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }

        &:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }

        &:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }

        &:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }

        &:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }

        &:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }
    }
}

/* Bloc Plus */
.plus {
    @include display-flex(column);
    text-align: center;
    background-image: url(../../assets_opti/autre/agate_opti.webp);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    color: $white-color;
    text-shadow: 0px 0px 5px $black-color;
    box-shadow: $primary-color 0px -70px 50px -75px inset, $black-color 0px 70px 50px -75px inset;
    gap: 30px;
    padding: 0px 20px 40px 20px;
    margin: 60px 0px;

    & h2 {
        font-weight: bold;
        font-size: 38px;
        letter-spacing: 2px;
        padding: 30px 0px;
    }

    &_ensemble {
        @include display-flex(row);
    }

    &_bloc {
        width: 20%;
        @include display-flex(column);
        gap: 30px;

        & h3 {
            font-size: 20px;
            font-weight: bold;
            padding: 0px 10px;
        }

        & i {
            font-size: 50px;
            color: $white-color;
        }

        &:hover i {
            animation: giggle 2s cubic-bezier(.36,.07,.19,.97) both;
        }
    }
}

/* Page "Destockage" */
.destockage, .resultat {
    @include display-flex(column);
    justify-content: center;
    margin: 60px 60px 0px 60px;

    & span {
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
    }

    &_titre {
        @include display-flex(column);
        text-align: center;
        font-size: 20px;
        padding-bottom: 30px;
        gap: 20px;
    }

    &_boutons {
        @include display-flex(row);
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        padding-top: 40px;
        gap: 20px;
    }

    & .nb_articles {
        position: sticky;
        top: 69px;
        z-index: 10;
        text-align: center;
        background-color: $white-color;
        box-shadow: 0px 10px 10px -13px #00000050;
        padding: 10px 0px;
    }
}

/* Page "Recherche" */
.resultat {
    & .nouvelleRecherche {
        &_info {
            @include display-flex(column);
            align-items: center;
            text-align: center;
            padding: 10px 10px 20px 0px;
            font-size: 20px;
            gap: 10px;
        }

        &_barreDeRecherche {
            width: 100%;
            @include display-flex(row);
            justify-content: center;
            align-items: center;
            padding-bottom: 30px;

            &_input {
                width: 75%;
                padding: 10px 45px 10px 20px;
                border-radius: 20px;
                border: 1px solid $black-color;
            }
        
            &_loupe {
                color: $primary-color;
                position: relative;
                right: 40px;
            }
        }
    }
}

/* Page "Livraison & Retour" */
.livraison {
    padding-bottom: 40px;

    & h1, & h2 {
        font-size: 34px;
        font-weight: bold;
    }

    & h3 {
        font-size: 26px;
    }

    & p {
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
        font-size: 18px;
    }

    &_conteneur {
        @include display-flex(column);
        justify-content: space-between;
        text-align: center;
        padding: 40px;
    }
    
    &_titre {
        display: flex;
        justify-content: center;
    }
    
    &_texte {
        @include display-flex(row);
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        gap: 10px;

        &_livraison, &_retrait {
            width: 50%;
        }

        &_livraison {
            @include display-flex(column);
            gap: 8px;
        }
    }

    &_couts {
        @include display-flex(row);
        justify-content: space-evenly;

        & p {
            padding: 0px;
        }
    }
    
    &_ligne {
        width: 150px;
        align-self: center;
        border-bottom: 3px solid $black-color;    
        padding-bottom: 10px;
    }

    &_espace {
        padding-top: 30px;
    }
}

/* Pages "CGV" & "Mentions Légales" */
.cgv, .mentionsLegales {
    @include display-flex(column);
    gap: 20px;
    padding: 40px;

    & h1 {
        font-size: 40px;
        text-align: center;
    }
   
    & h2 {
        font-size: 22px;
        padding-left: 40px;
    }

    & div {
        @include display-flex(column);
        gap: 15px;
    }

    & p {
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
        font-size: 13px;
    }

    & .p_un {
        padding-top: 20px;
    }

    &_ligne {
        width: 150px;
        align-self: center;
        border-bottom: 3px solid $black-color;   
    }
}

.mentionsLegales {
    &_edition {
        align-self: center;
    }
}

/* Offre de lancement du site */
.cgv {
    & .conditionsOffre {
        border: 2px solid $primary-color;
        border-radius: 20px;
        padding: 20px
    }
}

/* Simulateur */
.espaceClient {
    &_construction {
        @include display-flex(column);
        text-align: center;
        justify-content: center;
        border: solid 5px $tertiary-color;
        padding: 40px 0px;
    
        & h1 {
            font-size: 40px;
            text-transform: uppercase;
            text-align: center;
            background-color: $black-color;
            color: $tertiary-color;
            padding : 0px 20px 16px 20px;
        }
    
        & p {
            font-size: 20px;
            background-color: $black-color;
            color: $tertiary-color;
            padding : 0px 20px 16px 20px;
            margin: 0px;
        }
    }
}

/* Erreur */
.erreur {
    @include display-flex(column);
    align-items: center;
    background-color: $tertiary-color;
    padding: 40px 0px 60px 0px;

    & img {
        height: auto;
        width: 50%;
    }

    &_title {
        font-size: calc(1.4em + 1.4vw);
    }

    &_texte {
        text-align: center;
        font-size: calc(1em + 1vw);
    }
}

/* Boutons */
.bouton {
    &_espaceClient, &_erreur {
        width: fit-content;
        align-self: center;
        font-size: 30px;
        background-color: $tertiary-color;
        color: $black-color;
        border: 2px solid $black-color;
        border-radius: 20px;
        padding: 8px 16px;
        margin-top: 40px;

            &:hover {
                color: $tertiary-color;
                background-color: $black-color;
                transition: all 0.6s linear;
            }
    }
}

.boutonStock, .boutonPromo {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 7px 20px;
    border: 1px solid $primary-color;
    color: $white-color;
    background-color: $primary-color;
    border-radius: 20px;
    box-shadow: $primary-color 0px 0px 10px;

    &:hover {
        border: 1px solid red;
        background-color: red;
        box-shadow: red 0px 0px 10px;
        transition: all 0.4s linear;
    }
}

/* Page-spinner */
.page-spinner {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: $tertiary-color;

    &_interieur {
        height: 200px;
        width: 200px;
        position: relative;
        transform: translateZ(0deg) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
    
        & div {
            position: absolute;
            width: 60px;
            height: 60px;
            top: 26.66666666666669px;
            left: 26.66666666666669px;
            background-image: url(../../assets_opti/autre/LP1_opti.webp);
            background-size: cover;
            box-shadow: 0px 0px 15px 1px #00000050;
            animation: ldio-umsr1ybtmz 1.25s cubic-bezier(0,0.5,0.5,1) infinite;
            animation-delay: -0.37499999999999994s;
        
            &:nth-child(2) {
                top: 26.66666666666669px;
                left: 113.33333333333337px;
                background-image: url(../../assets_opti/autre/LP4_opti.webp);
                background-size: cover;
                box-shadow: 0px 0px 15px 1px #00000050;
                animation-delay: -0.25s;
            }
        
            &:nth-child(3) {
                top: 113.33333333333337px;
                left: 26.66666666666669px;
                background-image: url(../../assets_opti/autre/LP3_opti.webp);
                background-size: cover;
                box-shadow: 0px 0px 15px 1px #00000050;
                animation-delay: 0s;
            }
        
            &:nth-child(4) {
                top: 113.33333333333337px;
                left: 113.33333333333337px;
                background-image: url(../../assets_opti/autre/LP2_opti.webp);
                background-size: cover;
                box-shadow: 0px 0px 15px 1px #00000050;
                animation-delay: -0.125s;
            }
        }
    }
}

/* Offre de Lancement du Site */
.offre {
    height: 75px;
    width: 100%;
    @include display-flex(row);
    position: relative;
    z-index: 10;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    color: $white-color;
    background-color: $black-color;

    & img {
        height: 60px;
        width: auto;
    }

    &_infos {
        @include display-flex(column);
        
        & h2 {
            font-size: 20px;
        }

        & h3 {
            font-size: 18px;
        }

        & a:hover {
            text-decoration: underline;
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .plus {
        & h2 {
            font-size: 30px;
        }

        &_bloc {
            & i {
                font-size: 40px;
            }

            & h3 {
                font-size: 18px;
            }
        }
    }

    .livraison {
        &_conteneur {
            padding: 0px 40px;
        }

        &_titre{
            padding-top: 30px;
        }

        &_texte {
            @include display-flex(column);
        }
        
        &_texte_livraison, &_texte_retrait {
            width: 100%;
        }
    }

    .cgv, .mentionsLegales {
        padding: 20px;
    }

    .offre_infos {
        & h2 {
            font-size: 18px;
        }

        & h3 {
            font-size: 16px;
        }
    }
}

/* Tablet */
@media all and (max-width: 767px) {
    .plus {
        padding: 0px 5px 25px 5px;

        & h2 {
            font-size: 24px;
            padding: 15px 0px;
        }

        &_bloc {
            & i {
                font-size: 30px;
            }

            & h3 {
                font-size: 14px;
            }
        }
    }

    .spinner {
        & div {
            transform-origin: 40px 30px;

            &:after {
                width: 3px;
                height: 8px;
            }
        }
    }

    .espaceClient_construction {
        & h1 {
            font-size: 30px;
        }

        & p {
            font-size: 16px;
        }
    }

    .bouton {
        &_espaceClient, &_erreur {
            font-size: 22px;
        }
    }

    .livraison {
        & p {
            font-size: 14px;
        }

        &_conteneur {
            padding: 0px 10px;
        }

        .maps_img {
            height: 300px;
            width: 90%;
        }
    }

    .offre_infos {
        & h2 {
            font-size: 16px;
        }

        & h3 {
            font-size: 14px;
        }
    }

    .destockage, .resultat {
        margin: 40px 40px 0px 40px;
    
        &_titre {
            font-size: 18px;
            padding-bottom: 40px;
        }
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .plus {
        background-attachment: scroll;
        margin: 30px 0px;
        
        &_bloc h3 {
            font-size: 10px;
            padding: 0px 2px;
        }
    }
    
    .espaceClient_construction {
        & h1 {
            font-size: 26px;
        }
    }

    .bouton {
        &_espaceClient, &_erreur {
            font-size: 20px;
        }
    }

    .boutonStock, .boutonPromo {
        font-size: 18px;
    }

    .destockage_boutons {
        padding-top: 20px;
    }

    .offre {
        & img {
            height: 30px;
        }
    
        &_infos {
            & h2 {
                font-size: 14px;
            }
    
            & h3 {
                font-size: 12px;
            }
        }
    }
}