@import "../../utils/Utils.scss";

/* Code */
.produitsMain, .destockage, .resultat {
    @include display-flex(column);
    margin: 0px 100px;

    & .recap {
        @include display-flex(row);
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
        background-color: $white-color;
        gap: 30px;
        padding-top: 20px;

        &_nbArticles {
            display: flex;
            font-size: 16px;
        }

        &_stockage {
            display: none;
            align-items: center;
            text-align: center;
            background-color: $secondary-color;
            box-shadow: 0px 0px 8px 1px #00000050;
            padding: 5px 15px;
            gap: 10px;

            & .iconeX {
                height: auto;
                @include display-flex(row);

                & i {
                    height: 15px;
                    width: 15px;
                    cursor: pointer;
                }
            }

            &.visible {
                display: flex !important;
            }

        }
    }

    & .listeProduits {
        @include display-flex(row);
        flex-wrap: wrap;
        padding: 20px 50px 0px 50px;
        gap: 2.66%;

        &_ligne {
            @include display-flex(row);
            justify-content: center;
            gap: 40px;
        }

        /* Card Article */
        & .cardArticle {
            width: 23%;
            @include display-flex(column);

            &_photo {
                width: 100%;
                position: relative;
                box-shadow: 0px 3px 7px #00000080;
                transition: all 2s ease-in-out;

                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                &:hover {
                    box-shadow: 0px 3px 10px #00000090;

                    & .cardArticle_photo {
                        &_un {
                            display: none;
                        }

                        &_deux {
                            display: flex;
                        }
                    }
                }

                & img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &_deux{
                    display: none;
                }
            }

            &_info {
                @include display-flex(column);
                justify-content: space-between;
                padding: 10px 0px 70px 0px;
                gap: 5px;

                &_nom {
                    min-height: 38px;
                    font-size: 16px;
                }

                &_category {
                    font-size: 14px;
                }

                &_prix {
                    @include display-flex(row);
                    align-items: flex-end;
                    padding-top: 5px;
                    gap: 3%;

                    &_gauche {
                        font-size: 20px;
                        font-weight: bold;
                    }

                    &_droite {
                        font-size: 14px;
                    }
                    
                    &_barre {
                        @include display-flex(row);
                        align-items: flex-end;
                        gap: 3%;
                        font-size: 16px;
                        color: red;
                        text-decoration: line-through;
                        padding-top: 10px;

                        &_gauche {
                            font-size: 16px;
                            font-weight: bold;
                        }
    
                        &_droite {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

/* Bouton Afficher Plus de Produits */
.plusProduits {
    display: flex;
    position: relative;

    &_seul {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &_ensemble {
        width: 100%;
        @include display-flex(row);
        justify-content: space-between;
        gap: 20px;
    }

    &_gauche {
        width: 25%;
    }

    &_centre {
        width: 50%;
        @include display-flex(column);
        align-items: center;
        text-align: center;
        cursor: pointer;
    }

    &_droite {
        width: 25%;
        display: flex;
        justify-content: right;
        text-align: center;
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
        font-size: 15px;
        padding-top: 10px;
    }
       
    &_bouton {
        width: fit-content;
        display: flex;
        justify-content: center;
        color: $white-color;
        background-color: $primary-color;
        padding: 10px 30px;
        border-radius: 20px;
        font-size: 22px;
        transition: all 0.6s;

        &.bouton_encore{
            &:hover {
                opacity: 0.7;
            }
        }

        &.bouton_fini{
            opacity: 0.7;
        }
    }

    &_fleche {
        animation: plusProductArrow 4s linear infinite;
        transition: all 0.6s;

        &:hover {
            opacity: 0.7;
        }
    }

    &_spinner {
        top: -17.5px;
        left: 10%;

        & div {
            &:after {
                top: 12px;
                left: 37px;
            }
        }
    }
}

.bullePromo {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #FFFFFF;
    font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
    font-size: 13px;
    font-weight: bold;
    background-color: red;
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 2;
}

.bulleStock {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $white-color;
    font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
    font-size: 13px;
    font-weight: bold;
    background-color: $primary-color;
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 2;
}

.drapeau {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 2;
    height: 30px;
    width: 30px;
}

.ariane {  
    padding: 30px 0px 20px 0px;

    & ul {
        display: flex;
        list-style: none;
        margin: 0px;
        padding: 0px;
        font-size: 20px;
    }

    & li {
        @include display-flex(row);
    }

    & li:not(:last-child) a, & li:not(:last-child) span {
        color: $primary-color;
        font-weight: bold;

        &:hover {
            opacity: 0.7;
        }
    }

    & li:not(:last-child)::after {
        display: inline-block;
        margin: 0px 16px;
        content: ">";
    }
}

.filtres {
    position: sticky;
    top: 69px;
    z-index: 20;
    display: flex;
    justify-content: left;
    padding-top: 10px;
    @include display-flex(row);
    flex-wrap: wrap;
    align-content: center;
    background-color: $white-color;
    
    &_bloc {
        width: 100%;
        @include display-flex(row);
        flex-wrap: wrap;
        font-family: $font-family-primary, $font-family-secondary, $font-family-alternate, $font-family-default;
        box-shadow: 0px 10px 10px -13px #00000050;
        padding-bottom: 10px;
        margin-left: 50px;
        gap: 20px;

        & .boutonsFiltre, & .boutonsFiltreSize {
            @include display-flex(row);
            align-items: center;
            justify-content: space-between;
            padding: 5px 7px 5px 10px;

            &:hover .boutonsFiltre_filtre {
                opacity: 0.7;
            }

            &_entier {
                @include display-flex(row);
                align-items: center;
                gap: 10px;
                cursor: pointer;
                margin-right: 15px;
            }

            &_icones {
                display: flex;
                height: auto;
                width: auto;

                & img {
                    height: 18px;
                    width: 20px;
                }
            }

            &_enStock {
                height: fit-content;
                display: flex;
                align-content: center;
                font-size: 12px;
                font-weight: bold;
                box-shadow: $primary-color 0px 0px 3px;
                padding: 9px 20px;
                cursor: pointer;

                &.stockOui {
                    color: $white-color;
                    background-color: #045803 !important;

                    &:hover {
                        background-color: $black-color !important;
                        transition: all 0.2s linear;
                    }
                }
                
                &.stockNon {
                    color: $black-color;

                    &:hover {
                        color: $white-color;
                        background-color: $black-color !important;
                        transition: all 0.2s linear;
                    }
                }
            }
        }

        & ul {
            display: none;                
            flex-direction: column;
            font-family: $font-family-primary, $font-family-secondary, $font-family-alternate, $font-family-default;
            flex-wrap: wrap;
            list-style: none;
            font-size: 12px;
            padding-left: 20px;
            gap: 5px;
        }

        & h2 {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &_produit {
        &_titre {
            @include display-flex(row);
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid $black-color;
            padding-bottom: 10px;
            gap: 20px;  
            cursor: pointer;

            &_fleche {
                display: flex;
                font-size: 25px;
                font-weight: bolder;
                transition: transform 0.2s;
            }
        }

        &_bouton {
            display: flex;
            justify-content: center;
        }
    }

    &_chargementStock {
        height: auto;
        width: auto;
        @include display-flex(column);
        justify-content: center;
        align-items: center;
        gap: 30px;

        & .stockSelected {
            color: $white-color;
            background-color: red;
            transition: all 0.2s linear;

            &:hover {
                color: $black-color;
                background-color: $secondary-color !important;
                transition: all 0.2s linear;
            }
        }
        
    }

    &_spinner {
        right: 66px;
        bottom: 66px;

        & div {
            &:after {
                top: 12px;
                left: 37px;
            }
        }
    }
}

.menuDeroulant {
    @include display-flex(column);
    gap: 10px;
}

.aide {
    @include display-flex(column);
    padding: 20px;
    margin: 80px 0px;
    background-color: $secondary-color;
    gap: 15px;

    &_paragraphe {
        &_titre {
            font-weight: bold;
        }

        &_texte {      
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
            font-size: 16px;
        }
    }

    &_ligne {
        align-self: center;
        width: 50%;
        border-bottom: 3px solid $primary-color;
    }
}

.catSelected {
    background-color: $tertiary-color;
    border: 1px solid $primary-color;
    border-radius: 20px;
    box-shadow: $primary-color 0px 0px 10px;
    
    & .boutonsFiltre {
        &_entier {
            font-weight: bold;
        }

        &_enStock {
            display: flex !important;
        }
    }
}

/* Grand Ecran */
@media all and (min-width: 1799px) {
    .produitsMain, .destockage, .resultat {
        & .recap {
            &_nbArticles {
                font-size: 20px;
            }
        }

        & .filtres_bloc {
            margin-left: 80px;

            & h2 {
                font-size: 20px;
            }

            & ul {
                font-size: 18px;
            }
        }

        & .listeProduits {
            padding: 10px 80px 0px 80px;

            & .cardArticle_info {
                &_nom {
                    font-size: 18px;
                }

                &_prix {
                    &_gauche {
                        font-size: 22px;
                    }

                    &_droite {
                        font-size: 16px;
                    }

                    &_barre {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .produitsMain, .destockage, .resultat {
        margin: 0px 65px;

        & .filtres_bloc {
            margin-left: 0px;
        }

        & .listeProduits {
            padding: 20px 35px 0px 35px;
            gap: 3.5%;

            & .cardArticle {
                width: 31%;

                &_info {

                    &_prix {
                        font-size: 18px;

                        &_barre {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
  
    .ariane {    
        & ul {
            font-size: 18px;
        }
    }

    .plusProduits_bouton {
        font-size: 20px;
    }

    .aide {
        margin: 80px 0px;
    }
}

/* Tablet */
@media all and (max-width: 767px) {
    .produitsMain, .destockage, .resultat {
        margin: 0px 50px;

        & .filtres {
            position: static;
            
            &_bloc {
                justify-content: center;
                align-items: center;
                margin-left: 0px;
                gap: 30px;
            }

            &_produit {
                width: 75%;

                &_titre {
                    border-bottom: 1px solid $black-color;

                    & h2 {
                        font-size: 14px;
                    }

                    &_fleche {
                        font-size: 18px;
                    }
                }
            }
        }

        & .recap {
            top: 69px;
            margin-top: 10px;
        }

        & .listeProduits{
            padding: 10px 25px 0px 25px;
            gap: 5%;

            & .cardArticle {
                width: 47.5%;
                
                &_info {                    
                    &_prix {
                        font-size: 18px;
    
                        &_barre {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .plusProduits {
        &_spinner {  
            top: -8px; 
            right: 15%;
        }
    }

    .ariane {
        & li:not(:last-child)::after {
            margin: 0px 8px;
        }
    }

    .aide {
        margin: 40px 0px;
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .produitsMain, .destockage, .resultat {
        margin: 0px 25px;

        & .listeProduits{
            padding: 10px 15px 0px 15px;

            .cardArticle {
                width: 100%;

                &_info {
                    &_nom {
                        min-height: 0px;
                        font-size: 18px;
                    }

                    &_prix {
                        &_gauche {
                            font-size: 20px;
                        }

                        &_droite {
                            font-size: 16px;
                        }

                        &_barre {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }

        & .plusProduits_droite {
            font-size: 13px;
            align-items: center;
        }
    }

    .plusProduits {
        &_bouton {
            font-size: 16px;
            padding: 5px 15px;
        }
    }

    .ariane {
        & ul {
            font-size: 16px;
        }
    }

    .aide {
        padding: 10px;

        &_paragraphe {
            &_titre {
                font-size: 16px;
            }

            &_texte {
                font-size: 14px;
            }
        }
    }
}