/* Import de polices */
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* Variables */
$primary-color: #373a3c;
$secondary-color: #D8D8D8;
$tertiary-color: #FAFAFA;
$black-color: #28292A;
$white-color: #FFFFFF;
$font-family-primary: "Libre Caslon Text";
$font-family-secondary: "Jost";
$font-family-alternate: "Lato";
$font-family-default: sans-serif;

/* Body */
html, body {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

body {
	font-family: $font-family-primary, $font-family-secondary, $font-family-alternate, $font-family-default;
    font-size: 18px;
    color: $black-color;
	margin: 0px;
}

h1, h2, h3, h4 {
    font-weight: normal;
    margin: 0px;
}

button {
	font-family: $font-family-primary, $font-family-secondary, $font-family-alternate, $font-family-default;
    cursor: pointer;
    padding: 0px;
    color: $black-color;
    background: none;
    border: none;
}

p {
    margin: 0px;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Autre */
.colorRed {
    color: red;
}

/* Mixins */
@mixin display-flex($flex-direction) {
    display: flex;
    flex-direction: $flex-direction;
}

/* Keyframes */
@keyframes anim_un {
    0% {
        background-position: 0px 0px;
    }
    50% {
        background-position: 0px -50px; 
    }
    100% {
        background-position: 0px 0px;
    }
}

@keyframes anim_deux {
    0% {
        background-position: 0px 0px;
    }
    50% {
        background-position: 0px -25px; 
    }
    100% {
        background-position: 0px 0px;
    }
}

@keyframes anim_trois {
    0% {
        background-position: 0px 0px;
    }
    50% {
        background-position: 0px -12.5px; 
    }
    100% {
        background-position: 0px 0px;
    }
}

@keyframes cat {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@keyframes ldio-umsr1ybtmz {
    0% { 
        transform: scale(1.3) 
    }
    100% { 
        transform: scale(1) 
    }
}

@keyframes cart {
    0%{
        left: -10%
    }
    40%, 60% {
        left: 50%
    }
    100% {
        left: 110%
    }
}
    
@keyframes box {
    0%, 40% {
        top: -20%
    }
    60% {
        top: 36%;
        left: 53%
    }
    100% {
        top: 40%;
        left: 112%
    }
}

@keyframes addcart {
    0%, 30% {
        opacity: 1
    }
    30%, 100% {
        opacity: 0
    }
}

@keyframes added {
    0%, 80% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes giggle {
    0% {
        transform: rotate(8deg);
    }
    25% {
        transform: rotate(-8deg);
    }
    50% {
        transform: rotate(16deg);
    }
    75% {
        transform: rotate(-4deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(1px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-2px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }
}

@keyframes plusProductArrow {
    50% {
      transform: translate(0, 10px);
    }

    100% {
      transform: translate(0, 0);
    }
}

@keyframes gradiant {
    0% {
        filter: hue-rotate(0deg);
    }

    10% {
        filter: hue-rotate(20deg);
    }

    20% {
        filter: hue-rotate(30deg);
    }

    30% {
        filter: hue-rotate(40deg);
    }

    50% {
        filter: hue-rotate(50deg);
    }
    
    70% {
        filter: hue-rotate(40deg);
    }

    80% {
        filter: hue-rotate(30deg);
    }

    90% {
        filter: hue-rotate(20deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}