@charset "UTF-8";
/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
.gammes {
  width: 100%;
  color: #FAFAFA;
}
.gammes_conteneur {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 2px #28292A;
  padding-top: 100px;
}
.gammes_conteneur h1 {
  text-align: center;
  color: #FAFAFA;
  font-size: 56px;
  letter-spacing: 5px;
  padding: 0px 20px;
  text-shadow: 0px 0px 3px #28292A;
}
.gammes_conteneur p {
  text-align: center;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 22px;
  padding: 20px 50px;
  color: #FAFAFA;
}
.gammes_conteneur_ligne {
  width: 150px;
  align-self: center;
  border-bottom: 5px solid #FAFAFA;
  margin-top: 20px;
}
.gammes_contenu_premier, .gammes_contenu_second {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #28292A;
  gap: 50px;
}
.gammes_contenu_premier {
  padding: 50px 0px;
}
.gammes_contenu_hidden {
  display: none;
}
.gammes_contenu_titre {
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: right;
  font-size: 40px;
  font-weight: bold;
  padding-left: 10px;
}
.gammes_contenu_texte {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Jost", "Lato", sans-serif;
  font-size: 18px;
  padding-right: 100px;
  gap: 8px;
}
.gammes_categories {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-shadow: 0px 0px 2px #28292A;
  padding: 60px 0px;
  gap: 20px;
}
.gammes_categories_card {
  height: 200px;
  width: 100%;
  overflow: hidden;
}
.gammes_categories_card img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.gammes_categories_card a {
  height: 100%;
  width: 100%;
  display: flex;
  color: #FAFAFA;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  background-position: center;
  background-size: cover;
}
.gammes_categories_card a:hover {
  transform: scale(1.2);
}
.gammes_categories_card a:hover h2 {
  color: #D8D8D8;
}
.gammes_categories_card h2 {
  display: flex;
  position: absolute;
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
  text-shadow: 2px 2px 2px #28292A;
}
.gammes_categories_card_sols-et-murs a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-sol-murs_opti.webp);
}
.gammes_categories_card_les-faïences-murales a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-faiences_opti.webp);
}
.gammes_categories_card_les-extérieurs a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-exterieurs_opti.webp);
}
.gammes_categories_card_les-terrazzos a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-terrazzo_opti.webp);
}
.gammes_categories_card_les-mosaïques a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-mosaiques_opti.webp);
}
.gammes_categories_card_accessoires-carrelage a {
  background-image: url(../../../assets_opti/gammes/gammes-carrelage-accessoires_opti.webp);
}
.gammes_categories_card_massif a {
  background-image: url(../../../assets_opti/gammes/gammes-parquet-massif_opti.webp);
}
.gammes_categories_card_contrecollé a {
  background-image: url(../../../assets_opti/gammes/gammes-parquet-contrecolle_opti.webp);
}
.gammes_categories_card_stratifié-et-pvc a {
  background-image: url(../../../assets_opti/gammes/gammes-parquet-stratifie_opti.webp);
}
.gammes_categories_card_lame-murale-polystyrène a {
  background-image: url(../../../assets_opti/gammes/gammes-parquet-lame_opti.webp);
}
.gammes_categories_card_accessoires-parquet a {
  background-image: url(../../../assets_opti/gammes/gammes-parquet-accessoires_opti.webp);
}
.gammes_boutons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.gammes_boutons .articlesEnStock, .gammes_boutons .articlesEnPromo {
  padding: 0px 40px;
}
.gammes ~ .aide {
  margin: 80px 50px;
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
  .gammes_conteneur h1 {
    font-size: 50px;
  }
  .gammes_contenu_premier, .gammes_contenu_second {
    gap: 35px;
  }
  .gammes_contenu_titre {
    width: 30%;
  }
  .gammes_contenu_texte {
    width: 70%;
    padding-right: 80px;
  }
}
/* Tablet */
@media all and (max-width: 767px) {
  .gammes_conteneur h1 {
    font-size: 40px;
  }
  .gammes_conteneur p {
    font-size: 16px;
  }
  .gammes_contenu_premier, .gammes_contenu_second {
    gap: 15px;
  }
  .gammes_contenu_titre {
    width: 25%;
    font-size: 30px;
  }
  .gammes_contenu_texte {
    width: 75%;
    font-size: 14px;
    padding-right: 20px;
  }
  .gammes_categories_card {
    height: 150px;
  }
  .gammes_categories_card h2 {
    font-size: 50px;
  }
  .gammes ~ .aide {
    margin: 80px 20px;
  }
}
/* Smartphone */
@media all and (max-width: 549px) {
  .gammes_conteneur h1 {
    font-size: 30px;
  }
  .gammes_conteneur p {
    font-size: 12px;
  }
  .gammes_contenu_premier, .gammes_contenu_second {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: auto;
    width: 100%;
    padding: 20px 0px 40px 0px;
  }
  .gammes_contenu_titre {
    width: 100%;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    padding-bottom: 8px;
    padding-left: 0px;
  }
  .gammes_contenu_texte {
    width: 95%;
    font-size: 11px;
    padding: 0px;
  }
  .gammes_categories {
    padding: 40px 0px 20px 0px;
  }
  .gammes_categories_card h2 {
    font-size: 40px;
  }
  .gammes ~ .aide {
    margin: 50px 20px 40px 20px;
  }
}/*# sourceMappingURL=Gammes.css.map */