@import "../utils/Utils.scss";

/* Code */
/* Header */
.header {
    /*
    & .promo {
        height: 200px;
        width: 400px;
        @include display-flex(column);
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        bottom: 50px;
        left: 50px;
        color: $white-color;
        text-shadow: 0px 0px 2px $black-color;
        border: 1px solid $primary-color;
        border-radius: 20px;
        box-shadow: $primary-color 0px 0px 10px;
        background-color: rgba($primary-color, 0.5);
    
        & h2 {
            font-size: 40px;
            font-weight: bold;
        }
    
        & h3 {
            font-size: 28px;
            font-weight: bold;
        }
    }
    */
    display: flex;

    & a, i {
        display: flex;
        align-items: center;
        color: $white-color;
        cursor: pointer;
    }

    & video {
        height: 100%;
        width: 100%;
        display: flex;
        object-fit: cover;
        clip-path: inset(0px 0px 1px 0px);
    }

    &_nav {
        height: 90px;
        width: 100%;
        @include display-flex(row);
        align-items: center;
        position: absolute;
        //top: 75px;
        gap: 20px;
        transition: all 0.6s ease-in-out;

        & h2 {
            font-size: 22px;
            text-shadow: 0px 0px 2px $black-color;
        }

        & .header_gauche {
            height: 100%;
            width: 37.5%;
            @include display-flex(column);
            justify-content: space-around;
            text-align: center;

            &_megamenu {
                width: 100%;
                @include display-flex(row);
                justify-content: space-around;
                align-items: center;

                & li {
                    margin: 0px;
                }

                &_carrelage, &_parquet, &_sanitaire {
                    width: 33.33%;
                    justify-content: center;
                    display: flex;

                    &:hover h2 {
                        text-decoration: underline;
                        text-decoration-color: $white-color;
                    }
                }
        
                &_carrelage .bouton_carrelage {
                    &:hover + .categories_carrelage {
                        @include display-flex(column);
                    }
                }
        
                &_parquet .bouton_parquet {
                    &:hover + .categories_parquet {
                        @include display-flex(column);
                    }
                }
        
                &_sanitaire .bouton_sanitaire {
                    &:hover + .categories_sanitaire {
                        @include display-flex(column);
                    }
                }

                &_sanitaire {
                    &_remise {
                        @include display-flex(row);
                        font-size: 12px;
                        font-weight: bold;
                        position: relative;
                        top: -6px;
                        left: 3px;
                        border: 1px solid red;
                        border-radius: 20px;
                        background-color: red;
                        padding: 2px 5px;
                    }
                }
            
                & .bouton {
                    &_carrelage, &_parquet, &_sanitaire, &_contacts, &_livraison, &_simul {
                        border: none;
                        background: none;
                    }
                }
            }

            &_icones {
                width: 100%;
                @include display-flex(row);
                justify-content: space-evenly;
                font-size: 24px;
        
                & span {
                    height: 26px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none !important;
                    text-shadow: 0px 0px 2px $black-color;

                    &:hover i, &:hover {
                        color: $primary-color;
                    }
                }

                &_facebook, &_instagram, &_telephone {
                    width: 33.33%;
                    display: flex;
                    justify-content: center;

                    &:hover i, &:hover {
                        color: $primary-color;
                        text-shadow: 0px 0px 2px $white-color !important;
                    }
                }
            }
        }

        & .header_logo {
            &.logo_blanc {
                width: 25%;
                margin-top: 40px;
                filter: drop-shadow(-0.5px -0.5px $primary-color) drop-shadow(-0.5px -0.5px $primary-color);
    
                & a {
                    width: 100%;
                    justify-content: center;
    
                    & img {
                        height: auto;
                        width: 100%;
                        max-width: 400px;
                    }
                }
            }

            &.logo_noir {
                display: none;
                justify-content: center;

                &:hover {
                    filter: drop-shadow(0px 0px 5px $secondary-color);
                }
            }
        }
    
        & .header_droite {
            height: 100%;
            width: 37.5%;
            @include display-flex(column);
            justify-content: space-around;
            text-align: center;

            &_onglets {
                width: 100%;
                @include display-flex(row);
                justify-content: space-around;
                
                & a:hover {
                    text-decoration: underline;
                }

                &_contact, &_livraison, &_destockage {
                    width: 33.33%;
                    display: flex;
                    justify-content: center;
                }
            }
        
            &_icones {
                width: 100%;
                @include display-flex(row);
                justify-content: space-evenly;
                font-size: 24px;

                & i {
                    text-shadow: 0px 0px 2px $black-color;
                }

                & a {
                    height: 26px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_panier, &_recherche, &_espace {
                    width: 33.33%;
                    display: flex;
                    justify-content: center;

                    &:hover i, &:hover {
                        color: $primary-color;
                        text-shadow: 0px 0px 2px $white-color !important;
                    }

                    & .iconeRecherche {
                        width: 100%;
                        justify-content: center;
                    }
                }
            }
        }

        & .loupe_button {
            display: none;

            & i {
                font-size: 30px !important;
                color: $white-color !important;
            }
        }

        /* Header Scroll */
        &.scroll {
            height: 70px;
            justify-content: space-between;
            position: fixed;
            top: 0px;
            z-index: 50;
            background-color: $white-color;
            box-shadow: 0px -5px 10px -4px $secondary-color inset;
            padding-top: 0px;
            transition: all 0.6s ease-in-out;
        
            & h2, & i {
                font-size: 18px;
                text-shadow: none;
                color: $black-color;
            }

            & .header_gauche_megamenu_sanitaire_remise {
                font-size: 10px;
                padding: 1px 3px;
            }
        
            & .header_logo {
                margin-top: 0px !important;
        
                &.logo_noir {
                    display: flex;
        
                    & a {
                        & img {
                            max-width: 240px;
                        }
                    }
                }
        
                &.logo_blanc {
                    display: none;
                }
            }
        
            & .categories_carrelage, .categories_parquet, .categories_sanitaire {
                top: 25px;
                padding-top: 15px;
            }

            & .categories {
                &_sol, &_fai, &_ext, &_pie, &_mos, &_accCar, &_mas, &_con, &_str, &_lmp, &_accPar, &_lav, &_bai, &_dou, &_accSan {
                    &_infos {
                        top: 15px !important;
                    }
                }
            }
        
            & .recherche {
                top: 50px;
            }

            & .loupe_button {
                & i {
                    font-size: 30px !important;
                    color: $black-color !important;
                }
            }
        }
    }

    & .categories_carrelage {
        left: 40px;
    }

    & .categories_parquet {
        left: 80px;
    }

    & .categories_sanitaire {
        left: 120px;
    }

    & .categories_carrelage, .categories_parquet, .categories_sanitaire {
        height: 300px;
        width: 330px;
        display: none;
        position: absolute;
        top: 37px;
        z-index: 200;
        padding-top: 20px;
        animation: cat 0.4s linear forwards;

        & .categories {
            @include display-flex(column);
            justify-content: space-between;
            text-align: left;
            background-color: $white-color;
            padding: 20px 0px 20px 20px;
            border-top: 1px solid #00000020;
            border-left: 1px solid #00000020;
            border-bottom: 1px solid #00000020;
            border-radius: 20px;

            & h3 {
                height: 50px;
                display: flex;
                align-items: center;
                text-align: right;
                font-size: 16px;
                font-weight: bolder;
                text-transform: uppercase;
            }

            & a {
                color: $black-color;
            }
        
            & ul {
                justify-content: space-between;
                font-size: 14px;
                margin: 0px;
                list-style-type: none;
            }

            &_sol, &_fai, &_ext, &_pie, &_mos, &_accCar, &_mas, &_con, &_str, &_lmp, &_accPar, &_lav, &_bai, &_dou, &_accSan {
                @include display-flex(row);
                justify-content: right;
                transition: all 1s ease-in-out;

                &:hover {
                    text-decoration: underline;
                }

                &_titre {
                    margin-right: 30px;
                    gap: 20px;

                    &:hover {
                        text-decoration: underline;
                    }

                    &_logo {
                        display: none;
                        height: auto;
                        width: auto;
    
                        & img {
                            height: 22px;
                            width: 24px;
                        }
                    }
                }

                &_infos {
                    display: none;
                    position: absolute;
                    top: 20px;
                    left: 300px;
                    background-color: $white-color;
                    border-top: 1px solid #00000020;
                    border-right: 1px solid #00000020;
                    border-bottom: 1px solid #00000020;
                    border-radius: 0px 20px 20px 0px;
                    padding: 25px 0px 25px 55px;

                    &:hover {
                        @include display-flex(column);
                    }

                    & li:hover {
                        text-decoration: underline;
                    }
                }
            }

            &_sol, &_fai, &_ext, &_pie, &_mos, &_accCar {
                &_infos {
                    height: 290px;
                    width: 250px;
                }
            }

            &_mas, &_con, &_str, &_lmp, &_accPar {
                &_infos {
                    height: 240px;
                    width: 210px;
                }
            }

            &_lav, &_bai, &_dou, &_accSan {
                &_infos {
                    height: 190px;
                    width: 270px;
                }
            }

            &_sol:hover {
                & .categories_sol_titre_logo {
                    display: flex;
                }
            }

            &_sol_titre:hover {
                & .categories_sol_titre_logo {
                        display: flex;
                }

                & + .categories_sol_infos {
                    @include display-flex(column);
                }
            }

            &_fai:hover {
                & .categories_fai_titre_logo {
                    display: flex;
                }
            }

            &_fai_titre:hover + .categories_fai {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_ext:hover {
                & .categories_ext_titre_logo {
                    display: flex;
                }
            }

            &_ext_titre:hover + .categories_ext {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_pie:hover {
                & .categories_pie_titre_logo {
                    display: flex;
                }
            }

            &_pie_titre:hover + .categories_pie {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_mos:hover {
                & .categories_mos_titre_logo {
                    display: flex;
                }
            }

            &_mos_titre:hover + .categories_mos {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_accCar:hover {
                & .categories_accCar_titre_logo {
                    display: flex;
                }
            }

            &_accCar_titre:hover + .categories_accCar {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_mas:hover {
                & .categories_mas_titre_logo {
                    display: flex;
                }
            }

            &_mas_titre:hover + .categories_mas {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_con:hover {
                & .categories_con_titre_logo {
                    display: flex;
                }
            }

            &_con_titre:hover + .categories_con {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_str:hover {
                & .categories_str_titre_logo {
                    display: flex;
                }
            }

            &_str_titre:hover + .categories_str {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_lmp:hover {
                & .categories_lmp_titre_logo {
                    display: flex;
                }
            }

            &_lmp_titre:hover + .categories_lmp {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_accPar:hover {
                & .categories_accPar_titre_logo {
                    display: flex;
                }
            }

            &_accPar_titre:hover + .categories_accPar {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_lav:hover {
                & .categories_lav_titre_logo {
                    display: flex;
                }
            }

            &_lav_titre:hover + .categories_lav {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_bai:hover {
                & .categories_bai_titre_logo {
                    display: flex;
                }
            }

            &_bai_titre:hover + .categories_bai {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_dou:hover {
                & .categories_dou_titre_logo {
                    display: flex;
                }
            }

            &_dou_titre:hover + .categories_dou {
                &_infos {
                    @include display-flex(column);
                }
            }

            &_accSan:hover {
                & .categories_accSan_titre_logo {
                    display: flex;
                }
            }

            &_accSan_titre:hover + .categories_accSan {
                &_infos {
                    @include display-flex(column);
                }
            }
        }

        &:hover {
            @include display-flex(column);
        }
    }
    
    .recherche {
        height: auto;
        width: 90%;
        @include display-flex(column);
        position: absolute;
        top: 83px;
        right: 5%;
        z-index: 30;
        padding-top: 30px;
        animation: cat 0.6s linear forwards;

        &_conteneur {
            height: 100%;
            max-height: 75vh;
            width: 100%;
            @include display-flex(column);
            padding-bottom: 20px;
            border-radius: 20px;
            color: $black-color;
            background-color: $tertiary-color;
            box-shadow: inset 0 0 20px $secondary-color;

            &_blocUn {
                @include display-flex(row);
                width: 100%;
            }
            
            &_barreDeRecherche {
                width: 100%;
                @include display-flex(row);
                justify-content: center;
                padding-top: 30px;

                &_input {
                    width: 75%;
                    padding: 10px 45px 10px 20px;
                    border-radius: 20px;
                    border: 1px solid $black-color;
                }
            
                &_loupe {
                    color: $primary-color;
                    position: relative;
                    right: 40px;
                }

                &_spinner {
                    right: 10%;
            
                    & div {
                        &:after {
                            top: 12px;
                            left: 37px;
                        }
                    }
                }
            }

            &_info {
                @include display-flex(column);
                padding: 10px;
                font-size: 20px;
                gap: 10px;
            }

            &_resultat {
                width: 100%;
                @include display-flex(row);
                justify-content: left;
                flex-wrap: wrap;
                animation: cat 0.6s linear forwards;
                overflow-y: scroll;

                /* ScrollBar Custom */
                &::-webkit-scrollbar {
                    width: 10px;
                  }
                  
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px $primary-color; 
                    border-radius: 10px;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $primary-color; 
                    border-radius: 10px;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $tertiary-color; 
                }
    
                & .cardRecherche {
                    height: auto;
                    width: 21%;
                    @include display-flex(column);
                    align-items: center;
                    padding: 2%;
        
                    &_photo {
                        height: auto;
                        width: 100%;
                        position: relative;
                        box-shadow: 0px 0px 15px 1px #00000050;
        
                        &::after {
                            content: "";
                            display: block;
                            padding-bottom: 100%;
                        }

                        &:hover {
                            & .cardRecherche_photo {
                                &_un {
                                    display: none;
                                }
        
                                &_deux {
                                    display: flex;
                                }
                            }
                        }
        
                        & img {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }

                        &_deux{
                            display: none;
                        }
                    }
        
                    &_info {
                        &_nom {
                            height: auto;
                            width: 100%;
                            font-size: 18px;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }

    &_infos {
        @include display-flex(column);
        justify-content: left;
        position: absolute;
        bottom: 150px;
        left: 200px;
        font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: $white-color;
        text-shadow: 0px 0px 2px $black-color;
        padding: 30px;
        border-radius: 40px;
        background-color: rgba($primary-color, 0.4);
        transition: all 0.5s linear;

        &:hover {
            background-color: rgba($primary-color, 0.8);
        }

        &_titre {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 20px;
            font-size: 34px;
        }

        &_bouton {
            width: fit-content;
            font-size: 20px;
            background-color: $primary-color;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;
            animation: gradiant 5s linear infinite;

            &:hover {
                background-color: red;
                transition: all 0.4s linear;

                & span {
                    padding-right: 25px;

                    &:after {
                        opacity: 1;
                        right: 0;
                    }
                }
            }

            & span {
                display: inline-block;
                position: relative;
                transition: 0.5s;

                &:after {
                    content: '\00bb';
                    position: absolute;
                    opacity: 0;
                    top: -1px;
                    right: -20px;
                    transition: 0.5s;
                }
            }
        }
    }
}

.hideSearchingBar {
    display: none !important;
}

.hidden {
    display: none !important;
}

/* Burger menu + Bouton loupe */
.burger_button {
    display: none;
}

.loupe_button {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
}

/* Tailles + Backgrounds + Animations */
.header {
    &_home {
        height: 100vh;
    }

    &_carrelage, &_parquet, &_panier, &_destockage_carrelage-et-parquet, &_destockage_carrelage, &_destockage_parquet, &_produits_carrelage, &_produits_parquet, &_article_carrelage, &_article_parquet, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation: anim_un 10s linear infinite;
    }

    &_carrelage, &_parquet {
        height: 500px;
    }

    &_produits, &_article, &_promo, &_destockage {
        &_carrelage, &_parquet, &_sanitaire, &_carrelage-et-parquet {
            height: 375px;
        }
    }

    &_panier, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
        height: 300px;
    }

    &_carrelage {
        background-image: url("../../assets_opti/gammes/gammes-carrelage_opti.webp");
    }

    &_parquet {
        background-image: url("../../assets_opti/gammes/gammes-parquet_opti.webp");
    }

    &_produits {
        &_carrelage {
            background-image: url("../../assets_opti/produits/produits-carrelage_opti.webp");
        }

        &_parquet {
            background-image: url("../../assets_opti/produits/produits-parquet_opti.webp");
        }
    }

    &_article {
        &_carrelage {
            background-image: url("../../assets_opti/articles/articles-carrelage_opti.webp");
        }

        &_parquet {
            background-image: url("../../assets_opti/produits/produits-parquet_opti.webp");
        }
    }

    &_promo {
        &_carrelage, &_parquet, &_carrelage-et-parquet {
            background-image: url("../../assets_opti/gammes/bow-nice-gift.jpg");
        }
    }

    &_destockage {
        &_carrelage, &_carrelage-et-parquet {
            background-image: url("../../assets_opti/autre/enStock-carrelage_opti.webp");
        }

        &_parquet {
            background-image: url("../../assets_opti/autre/enStock-parquet_opti.webp");
        }
    }

    &_panier, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
        background-image: url("../../assets_opti/autre/pages_opti.webp");
    }
}

/* Footer */
.footer {
    @include display-flex(column);

    &_list {
        @include display-flex(row);
        font-size: 16px;
        color: $black-color;
        background-color: $secondary-color;
        padding: 25px 10px;
        gap: 15px;

        & h2 {
            font-size: 22px;
            font-weight: bold;
        }

        & ul {
            @include display-flex(column);
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
            padding: 0px;
            margin: 0px;
            gap: 10px;
        }

        & li {
            list-style: none;
        }
        
        & a, span {
            color: $black-color;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &_produits, &_services, &_contact_adresse {
        @include display-flex(column);
        text-align: center;
        gap: 20px;

        &_texte {
            @include display-flex(column);
            gap: 30px;
        }
    }

    &_produits {
        width: 20%;
    }

    &_services {
        width: 20%;

        & .rs {
            @include display-flex(row);
            justify-content: center;
            gap: 40px;
            padding-top: 10px;

            & i {
                font-size: 40px;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
    
    &_contact {
        @include display-flex(row);
        width: 60%;

        &_adresse {
            width: 50%;
        }

        &_telephone, &_email, &_showroom {
            text-align: center;

            & p {
                text-decoration: underline;
            }
        }
    }

    &_end {
        height: 60px;
        @include display-flex(row);
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $primary-color;
        color: $white-color;
        font-size: 14px;

        &_texte {
            width: 22.5%;
            display: flex;
            justify-content: center;
        }

        &_logo {
            width: 10%;
            display: flex;
            justify-content: center;
        }

        & img {
            height: auto;
            width: 46px;
        }
 
        & a {
            display: flex;
            color: $white-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* Grand Ecran */
@media all and (min-width: 1599px) {
    .header {
        & h2 {
            font-size: 28px;
        }
    }
}

@media all and (min-width: 1399px) {    
    .header {
        & .categories_parquet {
            left: 210px;
        }
        
        & .categories_sanitaire {
            left: 380px;
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .header {
        & h2 {
            font-size: 20px;
        }

        /*
        & .promo {
            & h2 {
                font-size: 34px;
            }

            & h3 {
                font-size: 22px;
            }
        }
        */

        &_nav {
            & .header_logo.logo_blanc {
                margin-top: 10px;
            }
        }

        & .categories_carrelage, .categories_parquet, .categories_sanitaire {
            top: 33px;
        }

        & .recherche {
            top: 79px;
        }

        &_carrelage, &_parquet, &_sanitaire, &_panier, &_produits_carrelage, &_produits_parquet, &_article_carrelage, &_article_parquet, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            animation: anim_deux 10s linear infinite;
        }

        &_carrelage, &_parquet, &_sanitaire {
            height: 400px;
        }

        &_produits, &_article {
            &_carrelage, &_parquet, &_sanitaire, &_carrelage-et-parquet {
                height: 350px;
            }
        }

        &_panier, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            height: 250px;
        }

        &_infos {
            left: 100px;

            &_titre {
                font-size: 30px;
            }
        }
    }

    .header_nav.scroll {
        & .categories_carrelage, .categories_parquet, .categories_sanitaire {
            top: 28px;
        }
    }

    .footer {
        &_list {
            & h2 {
                font-size: 18px;
            }
        }

        &_produits, &_services, &_contact {
            & ul {
                font-size: 14px;
            }
        }

        &_services .rs i {
            font-size: 30px;
        }

        &_contact_email .email {
            font-size: 13px;
        }
    }
}

/* Tablet */
@media all and (max-width: 767px) {    
    .header {
        & h2 {
            font-size: 16px;
        }

        /*
        & .promo {
            height: 125px;
            width: 300px;

            & h2 {
                font-size: 26px;
            }

            & h3 {
                font-size: 16px;
            }
        }
        */

        & .recherche_conteneur {
            &_info {
                font-size: 16px;
            }

            &_resultat .cardRecherche_info_nom {
                font-size: 14px;
            }

            &_barreDeRecherche_spinner {
                right: 13%;

                & div {
                    transform-origin: 40px 30px;
        
                    &:after {
                        width: 3px;
                        height: 8px;
                        top: 12px;
                        left: 37px;
                    }
                }
            }
        }

        &_nav {
            gap: 10px;
            justify-content: center;
            padding-top: 0px;

            & h2, & i {
                font-size: 22px;
                color: $black-color;
                text-shadow: none;
            }

            & .burger_bar, & .burger_bar::before, & .burger_bar::after {
                background-color: $white-color;
            }

            &.showBurger {
                & .burger_bar, & .burger_bar::before, & .burger_bar::after {
                    background-color: $black-color;
                }

                & .burger_button {
                    display: none;
                }
            }

            & .header_logo {
                width: 50%;
                text-align: center;
                
                & img {
                    min-width: 225px;
                }

                &.logo_blanc {
                    margin-top: 0px;
                }
            }

            & .categories_carrelage, .categories_parquet, .categories_sanitaire {
                width: 85%;
                top: 30px;

                & .categories {
                    & h3 {
                        font-size: 15px;
                    }

                    & ul {
                        font-size: 13px;
                    }
                }
            }

            & .recherche {
                top: 77px;
            }
            
            &.scroll {
                & h2, & i {
                    font-size: 22px;
                }
            }

            & .loupe_button {
                display: flex;
            }
        }

        &_carrelage, &_parquet, &_sanitaire, &_panier, &_produits_carrelage, &_produits_parquet, &_article_carrelage, &_article_parquet, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            animation: anim_trois 10s linear infinite;
        }

        &_carrelage, &_parquet, &_sanitaire {
            height: 350px;
        }

        &_panier, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            height: 225px;
        }

        & .categories_carrelage, & .categories_parquet, & .categories_sanitaire {
            left: 15px;
        }

        &_infos {
            width: calc(100% - 60px);
            left: 0px;
            bottom: 50px;
            border-radius: 0px;
            
            &_titre {
                font-size: 26px;
            }
        }
    
    }

    .header_gauche {
        height: 100%;
        position: fixed;
        left: -500px;
        top: 0;
        justify-content: space-evenly !important;
        align-self: center;
        visibility: hidden;
        background-color: $white-color;
        transition: all 0.8s ease-out;

        &_megamenu {
            flex-direction: column !important;
            align-items: center !important;
            gap: 25px;

            &_carrelage, &_parquet, &_sanitaire {
                padding: 0px !important;
            }

            &_carrelage:hover .categories_carrelage, &_parquet:hover .categories_parquet, &_sanitaire:hover .categories_sanitaire {
                display: none !important;
            }
        }

        &_icones {
            flex-direction: column !important;
            align-items: center !important;
            gap: 15px !important;
        }
    }

    .header_droite {
        display: none !important;
    }

    .header_nav.scroll {
        justify-content: center;

        & .header_logo {
            width: 35%;
            text-align: center;
            padding-top: 0px;
        }

        & .categories_carrelage, .categories_parquet, .categories_sanitaire {
            top: 36px;
        }

        & .recherche {
            top: 82px;
        }

        & .burger_bar, & .burger_bar::before, & .burger_bar::after {
            background-color: $black-color;
        }

        & .showBurger {
            & .burger_button {
                top: 20px !important;
            }
        }
    }

    .burger_button {
        height: 30px;
        width: 30px;
        display: flex;
        position: absolute;
        left: 20px;
        z-index: 25;       
        background: transparent;
        border: none;
        color: inherit;

        &:hover {
            cursor: pointer;
        }
    }

    .burger_bar, .burger_bar::before, .burger_bar::after {
        display: block;
        width: 30px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background-color: $black-color;
        transition: all 0.5s ease-in-out;
    }

    .burger_bar::before, .burger_bar::after {
        content: "";
        position: absolute;
        left: 0;
    }

    .burger_bar {
        &::before {
            transform: translateY(12px);
        }

        &::after {
            transform: translateY(24px);
        }
    }

    .showBurger {
        & .burger_bar {
            width: 0;
            background: transparent;
            padding-top: 10px;

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
        
        & .burger_button {
            position: fixed;
        }

        & .header_gauche {
            width: 45% !important;
            visibility: visible;
            left: 0;
            z-index: 100;
            padding-top: 0px;
        }
    }

    .footer {
        & h4 {
            padding: 0px;
        }

        &_list {
            @include display-flex(column);
            align-items: center;
            padding: 10px;
        }

        &_produits, &_services {
            @include display-flex(column);

            & ul {
                @include display-flex(row);
                justify-content: space-evenly;
                margin: 0px;
            }

            & .list_un {
                gap: 40px;
            }
        }

        &_produits, &_produits, &_services, &_contact {
            width: 100%;
            padding: 0px;

            & .rs {
                padding: 0px;
                gap: 40px;

                & li {
                    font-size: 0.6em;
                }
            }

            &_email .email {
                font-size: 14px;
            }
        }

        &_end {
            font-size: 13px;
        }
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .header {
        /*
        & .promo {
            height: 75px;
            width: 80%;
            left: 10%;

            & h2 {
                font-size: 18px;
            }

            & h3 {
                font-size: 14px;
            }
        }
        */

        &_carrelage, &_parquet, &_sanitaire, &_panier, &_produits_carrelage, &_produits_parquet, &_article_carrelage, &_article_parquet, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            background-attachment: scroll;
        }

        &_carrelage, &_parquet, &_sanitaire {
            height: 300px;
        }

        &_panier, &_livraison, &_espaceClient, &_cgv, &_mentionsLegales, &_erreur {
            height: 200px;
        }
    }

    .footer {
        &_list h2 {
            font-size: 16px;
        }

        &_services .rs i {
            font-size: 24px;
        }

        &_end {
            font-size: 10px;

            & img {
                height: auto;
                width: 35px;
            }
        }

        &_contact_email .email {
            font-size: 10.5px;
        }
        
        &_produits, &_services, &_contact {
            & ul {
                font-size: 12px;
            }
        }
    }
}