/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
.panierMain {
  background-color: #FAFAFA;
  padding: 50px 100px;
}

.panier {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.panier_gauche {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.panier_gauche_titre {
  background-color: #FAFAFA;
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #28292A;
}
.panier_gauche_corps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.panier_gauche_corps_card {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  box-shadow: #373a3c 0px 0px 10px;
  padding: 15px;
  gap: 20px;
}
.panier_gauche_corps_card_photo {
  height: 150px;
  width: 150px;
}
.panier_gauche_corps_card_texte {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.panier_gauche_corps_card_texte_haut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.panier_gauche_corps_card_texte_haut_gauche {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.panier_gauche_corps_card_texte_haut_gauche_nom {
  font-size: 22px;
  font-weight: bold;
}
.panier_gauche_corps_card_texte_haut_gauche_tarif {
  font-size: 16px;
}
.panier_gauche_corps_card_texte_haut_droite {
  display: flex;
  flex-direction: column;
}
.panier_gauche_corps_card_texte_haut_droite_ht {
  font-size: 22px;
  font-weight: bold;
}
.panier_gauche_corps_card_texte_haut_droite_ttc {
  font-size: 14px;
}
.panier_gauche_corps_card_texte_bas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}
.panier_gauche_corps_card_texte_bas_boutonUn, .panier_gauche_corps_card_texte_bas_boutonDeux {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.panier_gauche_corps_card_texte_bas_boutonUn_label, .panier_gauche_corps_card_texte_bas_boutonDeux_label {
  font-size: 14px;
}
.panier_gauche_corps_card_texte_bas_boutonUn_conteneur, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border: 1px solid #28292A;
  border-radius: 20px;
  gap: 10px;
}
.panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins, .panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus {
  cursor: pointer;
  padding: 5px;
}
.panier_gauche_corps_card_texte_bas_boutonUn_conteneur input, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur input {
  width: 90px;
  padding: 0px;
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  outline: none;
  cursor: text;
}
.panier_gauche_corps_card_texte_bas_boutonUn_conteneur input[type=number], .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.panier_gauche_corps_card_texte_bas_boutonUn_conteneur input[type=number]::-webkit-inner-spin-button, .panier_gauche_corps_card_texte_bas_boutonUn_conteneur input[type=number]::-webkit-outer-spin-button, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur input[type=number]::-webkit-inner-spin-button, .panier_gauche_corps_card_texte_bas_boutonDeux_conteneur input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.panier_gauche_corps_card_texte_bas_corbeille {
  padding: 10px;
}
.panier_gauche_boutons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
}
.panier_gauche_boutons_continuer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 14px;
  align-items: center;
}
.panier_gauche_boutons_continuer i {
  align-self: center;
}
.panier_gauche_boutons_continuer p {
  border-bottom: 1px solid #28292A;
}
.panier_droite {
  height: -moz-fit-content;
  height: fit-content;
  width: 35%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: sticky;
  top: 90px;
  gap: 20px;
}
.panier_droite_titre {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #28292A;
}
.panier_droite_corps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.panier_droite_corps_montants, .panier_droite_corps_paiements, .panier_droite_corps_engagements, .panier_droite_corps_question {
  display: flex;
  flex-direction: column;
  box-shadow: #373a3c 0px 0px 10px;
  background-color: #FFFFFF;
  padding: 20px;
  gap: 10px;
}
.panier_droite_corps_montants div, .panier_droite_corps_paiements div, .panier_droite_corps_engagements div, .panier_droite_corps_question div {
  display: flex;
  flex-direction: row;
}
.panier_droite_corps_montants_titre, .panier_droite_corps_paiements_titre, .panier_droite_corps_engagements_titre, .panier_droite_corps_question_titre {
  font-weight: bold;
  align-self: center;
}
.panier_droite_corps_montants_valider, .panier_droite_corps_paiements_valider, .panier_droite_corps_engagements_valider, .panier_droite_corps_question_valider {
  margin-top: 10px;
}
.panier_droite_corps_montants {
  gap: 20px;
}
.panier_droite_corps_montants div {
  justify-content: space-between;
  flex-wrap: wrap;
}
.panier_droite_corps_montants_livraison {
  opacity: 0.5;
}
.panier_droite_corps_montants_separateur {
  border-bottom: 1px solid #28292A;
}
.panier_droite_corps_montants_ttc {
  font-weight: bold;
}
.panier_droite_corps_paiements {
  text-align: center;
}
.panier_droite_corps_paiements_modes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 5px;
  padding: 0px 20px;
}
.panier_droite_corps_paiements_modes div {
  height: 40px;
  min-width: 50px;
  width: 20%;
  border: 1px solid #28292A;
}
.panier_droite_corps_paiements_modes div img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.panier_droite_corps_engagements_titre {
  text-align: center;
}
.panier_droite_corps_engagements div:not(:first-child) {
  height: 30px;
  align-items: center;
  text-align: center;
  padding-left: 30px;
  gap: 40px;
}
.panier_droite_corps_engagements span {
  width: 35px;
}
.panier_droite_corps_question {
  text-align: center;
}
.panier_valider {
  display: flex;
  align-self: center;
  text-align: center;
  border: 2px solid #28292A;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}
.panier_valider:hover {
  color: #FAFAFA;
  background-color: #373a3c;
  border-color: #373a3c;
  transition: all 0.4s linear;
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
  .panierMain {
    font-size: 15px;
    padding: 25px 50px;
  }
  .panier_gauche_corps_card_texte_haut {
    gap: 10px;
  }
  .panier_gauche_corps_card_texte_haut_gauche {
    gap: 0px;
  }
  .panier_gauche_corps_card_texte_haut_gauche_nom {
    font-size: 20px;
  }
  .panier_gauche_corps_card_texte_haut_gauche_tarif {
    font-size: 14px;
  }
  .panier_gauche_corps_card_texte_haut_droite_ht {
    font-size: 20px;
  }
  .panier_gauche_corps_card_texte_haut_droite_ttc {
    font-size: 12px;
  }
  .panier_droite_corps_engagements div:not(:first-child) {
    height: 50px;
    padding-left: 0px;
    gap: 10px;
  }
  .panier_droite_corps_engagements span {
    width: 35px;
  }
}
/* Tablet */
@media all and (max-width: 767px) {
  .panierMain {
    font-size: 12px;
    padding: 10px 20px;
  }
  .panier {
    gap: 10px;
  }
  .panier_gauche_titre {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .panier_gauche_corps_card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .panier_gauche_corps_card_texte_haut {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }
  .panier_gauche_corps_card_texte_haut_gauche {
    gap: 0px;
  }
  .panier_gauche_corps_card_texte_haut_gauche_nom {
    font-size: 16px;
  }
  .panier_gauche_corps_card_texte_haut_gauche_tarif {
    font-size: 12px;
  }
  .panier_gauche_corps_card_texte_haut_droite_ht {
    font-size: 16px;
  }
  .panier_gauche_corps_card_texte_haut_droite_ttc {
    font-size: 11px;
  }
  .panier_droite_titre {
    font-size: 16px;
    padding-bottom: 10px;
  }
}/*# sourceMappingURL=Panier.css.map */