@charset "UTF-8";
/* Import de polices */
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* Variables */
/* Body */
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  font-size: 18px;
  color: #28292A;
  margin: 0px;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0px;
}

button {
  font-family: "Libre Caslon Text", "Jost", "Lato", sans-serif;
  cursor: pointer;
  padding: 0px;
  color: #28292A;
  background: none;
  border: none;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Autre */
.colorRed {
  color: red;
}

/* Mixins */
/* Keyframes */
@keyframes anim_un {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -50px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_deux {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -25px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes anim_trois {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px -12.5px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes cat {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ldio-umsr1ybtmz {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 36%;
    left: 53%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes addcart {
  0%, 30% {
    opacity: 1;
  }
  30%, 100% {
    opacity: 0;
  }
}
@keyframes added {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes giggle {
  0% {
    transform: rotate(8deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(16deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes plusProductArrow {
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes gradiant {
  0% {
    filter: hue-rotate(0deg);
  }
  10% {
    filter: hue-rotate(20deg);
  }
  20% {
    filter: hue-rotate(30deg);
  }
  30% {
    filter: hue-rotate(40deg);
  }
  50% {
    filter: hue-rotate(50deg);
  }
  70% {
    filter: hue-rotate(40deg);
  }
  80% {
    filter: hue-rotate(30deg);
  }
  90% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
/* Code */
/* Bouton "Remonter en Haut de Page" */
.boutonScrollToTop {
  height: 70px;
  width: 70px;
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
  border-radius: 70px;
  transition: all 1s;
  z-index: 10;
}
.boutonScrollToTop:hover {
  background-color: #373a3c;
}
.boutonScrollToTop:hover .fa-chevron-up > path {
  color: #FFFFFF;
  transition: all 1s;
}
.boutonScrollToTop button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boutonScrollToTop .fa-chevron-up > path {
  color: #373a3c;
}

/* Loading-spinner */
.spinner {
  display: flex;
  position: absolute;
}
.spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.spinner div:after {
  content: " ";
  width: 4px;
  height: 12px;
  display: block;
  position: absolute;
  border-radius: 20%;
  background: #28292A;
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

/* Bloc Plus */
.plus {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-image: url(../../assets_opti/autre/agate_opti.webp);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #FFFFFF;
  text-shadow: 0px 0px 5px #28292A;
  box-shadow: #373a3c 0px -70px 50px -75px inset, #28292A 0px 70px 50px -75px inset;
  gap: 30px;
  padding: 0px 20px 40px 20px;
  margin: 60px 0px;
}
.plus h2 {
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 2px;
  padding: 30px 0px;
}
.plus_ensemble {
  display: flex;
  flex-direction: row;
}
.plus_bloc {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.plus_bloc h3 {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 10px;
}
.plus_bloc i {
  font-size: 50px;
  color: #FFFFFF;
}
.plus_bloc:hover i {
  animation: giggle 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Page "Destockage" */
.destockage, .resultat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px 60px 0px 60px;
}
.destockage span, .resultat span {
  font-family: "Jost", "Lato", sans-serif;
}
.destockage_titre, .resultat_titre {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  padding-bottom: 30px;
  gap: 20px;
}
.destockage_boutons, .resultat_boutons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-top: 40px;
  gap: 20px;
}
.destockage .nb_articles, .resultat .nb_articles {
  position: sticky;
  top: 69px;
  z-index: 10;
  text-align: center;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px -13px rgba(0, 0, 0, 0.3137254902);
  padding: 10px 0px;
}

/* Page "Recherche" */
.resultat .nouvelleRecherche_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 10px 20px 0px;
  font-size: 20px;
  gap: 10px;
}
.resultat .nouvelleRecherche_barreDeRecherche {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.resultat .nouvelleRecherche_barreDeRecherche_input {
  width: 75%;
  padding: 10px 45px 10px 20px;
  border-radius: 20px;
  border: 1px solid #28292A;
}
.resultat .nouvelleRecherche_barreDeRecherche_loupe {
  color: #373a3c;
  position: relative;
  right: 40px;
}

/* Page "Livraison & Retour" */
.livraison {
  padding-bottom: 40px;
}
.livraison h1, .livraison h2 {
  font-size: 34px;
  font-weight: bold;
}
.livraison h3 {
  font-size: 26px;
}
.livraison p {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 18px;
}
.livraison_conteneur {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 40px;
}
.livraison_titre {
  display: flex;
  justify-content: center;
}
.livraison_texte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  gap: 10px;
}
.livraison_texte_livraison, .livraison_texte_retrait {
  width: 50%;
}
.livraison_texte_livraison {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.livraison_couts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.livraison_couts p {
  padding: 0px;
}
.livraison_ligne {
  width: 150px;
  align-self: center;
  border-bottom: 3px solid #28292A;
  padding-bottom: 10px;
}
.livraison_espace {
  padding-top: 30px;
}

/* Pages "CGV" & "Mentions Légales" */
.cgv, .mentionsLegales {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}
.cgv h1, .mentionsLegales h1 {
  font-size: 40px;
  text-align: center;
}
.cgv h2, .mentionsLegales h2 {
  font-size: 22px;
  padding-left: 40px;
}
.cgv div, .mentionsLegales div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cgv p, .mentionsLegales p {
  font-family: "Jost", "Lato", sans-serif;
  font-size: 13px;
}
.cgv .p_un, .mentionsLegales .p_un {
  padding-top: 20px;
}
.cgv_ligne, .mentionsLegales_ligne {
  width: 150px;
  align-self: center;
  border-bottom: 3px solid #28292A;
}

.mentionsLegales_edition {
  align-self: center;
}

/* Offre de lancement du site */
.cgv .conditionsOffre {
  border: 2px solid #373a3c;
  border-radius: 20px;
  padding: 20px;
}

/* Simulateur */
.espaceClient_construction {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: solid 5px #FAFAFA;
  padding: 40px 0px;
}
.espaceClient_construction h1 {
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  background-color: #28292A;
  color: #FAFAFA;
  padding: 0px 20px 16px 20px;
}
.espaceClient_construction p {
  font-size: 20px;
  background-color: #28292A;
  color: #FAFAFA;
  padding: 0px 20px 16px 20px;
  margin: 0px;
}

/* Erreur */
.erreur {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
  padding: 40px 0px 60px 0px;
}
.erreur img {
  height: auto;
  width: 50%;
}
.erreur_title {
  font-size: calc(1.4em + 1.4vw);
}
.erreur_texte {
  text-align: center;
  font-size: calc(1em + 1vw);
}

/* Boutons */
.bouton_espaceClient, .bouton_erreur {
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
  font-size: 30px;
  background-color: #FAFAFA;
  color: #28292A;
  border: 2px solid #28292A;
  border-radius: 20px;
  padding: 8px 16px;
  margin-top: 40px;
}
.bouton_espaceClient:hover, .bouton_erreur:hover {
  color: #FAFAFA;
  background-color: #28292A;
  transition: all 0.6s linear;
}

.boutonStock, .boutonPromo {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding: 7px 20px;
  border: 1px solid #373a3c;
  color: #FFFFFF;
  background-color: #373a3c;
  border-radius: 20px;
  box-shadow: #373a3c 0px 0px 10px;
}
.boutonStock:hover, .boutonPromo:hover {
  border: 1px solid red;
  background-color: red;
  box-shadow: red 0px 0px 10px;
  transition: all 0.4s linear;
}

/* Page-spinner */
.page-spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #FAFAFA;
}
.page-spinner_interieur {
  height: 200px;
  width: 200px;
  position: relative;
  transform: translateZ(0deg) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.page-spinner_interieur div {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 26.6666666667px;
  left: 26.6666666667px;
  background-image: url(../../assets_opti/autre/LP1_opti.webp);
  background-size: cover;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  animation: ldio-umsr1ybtmz 1.25s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.375s;
}
.page-spinner_interieur div:nth-child(2) {
  top: 26.6666666667px;
  left: 113.3333333333px;
  background-image: url(../../assets_opti/autre/LP4_opti.webp);
  background-size: cover;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  animation-delay: -0.25s;
}
.page-spinner_interieur div:nth-child(3) {
  top: 113.3333333333px;
  left: 26.6666666667px;
  background-image: url(../../assets_opti/autre/LP3_opti.webp);
  background-size: cover;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  animation-delay: 0s;
}
.page-spinner_interieur div:nth-child(4) {
  top: 113.3333333333px;
  left: 113.3333333333px;
  background-image: url(../../assets_opti/autre/LP2_opti.webp);
  background-size: cover;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3137254902);
  animation-delay: -0.125s;
}

/* Offre de Lancement du Site */
.offre {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  color: #FFFFFF;
  background-color: #28292A;
}
.offre img {
  height: 60px;
  width: auto;
}
.offre_infos {
  display: flex;
  flex-direction: column;
}
.offre_infos h2 {
  font-size: 20px;
}
.offre_infos h3 {
  font-size: 18px;
}
.offre_infos a:hover {
  text-decoration: underline;
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
  .plus h2 {
    font-size: 30px;
  }
  .plus_bloc i {
    font-size: 40px;
  }
  .plus_bloc h3 {
    font-size: 18px;
  }
  .livraison_conteneur {
    padding: 0px 40px;
  }
  .livraison_titre {
    padding-top: 30px;
  }
  .livraison_texte {
    display: flex;
    flex-direction: column;
  }
  .livraison_texte_livraison, .livraison_texte_retrait {
    width: 100%;
  }
  .cgv, .mentionsLegales {
    padding: 20px;
  }
  .offre_infos h2 {
    font-size: 18px;
  }
  .offre_infos h3 {
    font-size: 16px;
  }
}
/* Tablet */
@media all and (max-width: 767px) {
  .plus {
    padding: 0px 5px 25px 5px;
  }
  .plus h2 {
    font-size: 24px;
    padding: 15px 0px;
  }
  .plus_bloc i {
    font-size: 30px;
  }
  .plus_bloc h3 {
    font-size: 14px;
  }
  .spinner div {
    transform-origin: 40px 30px;
  }
  .spinner div:after {
    width: 3px;
    height: 8px;
  }
  .espaceClient_construction h1 {
    font-size: 30px;
  }
  .espaceClient_construction p {
    font-size: 16px;
  }
  .bouton_espaceClient, .bouton_erreur {
    font-size: 22px;
  }
  .livraison p {
    font-size: 14px;
  }
  .livraison_conteneur {
    padding: 0px 10px;
  }
  .livraison .maps_img {
    height: 300px;
    width: 90%;
  }
  .offre_infos h2 {
    font-size: 16px;
  }
  .offre_infos h3 {
    font-size: 14px;
  }
  .destockage, .resultat {
    margin: 40px 40px 0px 40px;
  }
  .destockage_titre, .resultat_titre {
    font-size: 18px;
    padding-bottom: 40px;
  }
}
/* Smartphone */
@media all and (max-width: 549px) {
  .plus {
    background-attachment: scroll;
    margin: 30px 0px;
  }
  .plus_bloc h3 {
    font-size: 10px;
    padding: 0px 2px;
  }
  .espaceClient_construction h1 {
    font-size: 26px;
  }
  .bouton_espaceClient, .bouton_erreur {
    font-size: 20px;
  }
  .boutonStock, .boutonPromo {
    font-size: 18px;
  }
  .destockage_boutons {
    padding-top: 20px;
  }
  .offre img {
    height: 30px;
  }
  .offre_infos h2 {
    font-size: 14px;
  }
  .offre_infos h3 {
    font-size: 12px;
  }
}/*# sourceMappingURL=Autre.css.map */