@import "../styles/utils/Utils.scss";

/* Code */
.bodyMain {
    background-image: url(../assets_opti/autre/fond.webp);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

.mainHome {
    margin: 0px 150px;

    & .plus { 
        margin: 60px -150px;
    }
}

/* Gammes de Produits */
.gallery {
    @include display-flex(column);
    padding: 20px;
    gap: 20px;
    background: $tertiary-color;
    border: 0px solid $primary-color;
    box-shadow: rgba($primary-color, 0.4) 0px 0px 15px;
    border-radius: 10px;
    margin: 60px -60px;


    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &_un, &_six, &_sept {
        text-shadow: 0px 0px 2px $black-color;

        & a {
            transition: transform 0.5s;
    
            &:hover {
                transform : scale(1.15);
            }
        }

        &_titre {
            font-size: 50px;
            font-weight: bold;
        }
    }

    &_titre {
        &_main, &_ten, &_san {
            text-align: center;
            padding: 20px 0px 20px 0px;
    
            & h2 {
                font-size: 50px;
                font-weight: bold;
            }
    
            & h3 {
                font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                font-size: 28px;
            }
        }
    }

    &_car, &_par, &_san, &_ten {
        height: 250px;

        & h4 {
            position: absolute;
            text-align: center;
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
            letter-spacing: 0.8px;
        }

        & a {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $tertiary-color;
    
            &:hover {
                & h3, & h4 {
                    color: $secondary-color;
                }
            }
        }
    }

    &_ten {
        @include display-flex(row);
        height: 500px;
        width: 100%;
        gap: 1%;
    }
    
    &_un {
        @include display-flex(row);
        gap: 1%;
    }

    &_deux {
        width: 50%;
        @include display-flex(column);
        font-size: 46px;
        gap: 2%;
    }

    &_trois {
        height: 50%;
        @include display-flex(row);
        gap: 1.5%;
    }

    &_quatre {
        height: 100%;
        width: 50%;
        @include display-flex(column);
        font-size: 32px;
    }

    &_cinq {
        height: 100%;
        width: 33.33%;
        display: flex;
        font-size: 40px;
    }

    &_six {
        height: 100%;
        width: 33.33%;
        display: flex;
        font-size: 54px;
    }

    &_sept {
        height: 100%;
        width: 66.66%;
        @include display-flex(column);
        gap: 1.5%;
    }

    &_huit {
        height: 50%;
        width: 100%;
        display: flex;
        font-size: 54px;
        gap: 1.5%;
    }

    &_neuf {
        height: 100%;
        width: 50%;
        display: flex;
        font-size: 46px;
    }

    &_un, &_deux, &_trois, &_quatre, &_cinq, &_six, &_sept, &_huit, &_neuf {
        overflow: hidden;
        border-radius: 10px;
    }

    &_trois, &_cinq {
        position: relative;
    }
}

/* Mis en Avant */
.misEnAvant {
    //width: 100%;
    @include display-flex(column);
    align-items: center;
    padding-top: 20px;

    & h3 {
        display: flex;
        font-size: 28px;
        font-weight: bold;
    }

    &_titre {
        width: 100%;
        @include display-flex(row);

        & button {
            display: flex;
            justify-content: center;       
            font-weight: bold;
            color: $black-color;
            border: none;
            transition: transform 0.5s;
            cursor: pointer;

            &:hover {
                transform: scale(1.07);
            }
        }

        &_prix, &_top {
            height: 100%;
            width: 50%;
            @include display-flex(column);
            text-align: center;

            .titre-actif {
                & h3 {
                    font-weight: bold;
                    color: $primary-color;
                    transform: scale(1.07);
                }
            
                & ~.misEnAvant_titre_ligne {
                    display: flex;
                    border-bottom: 3px solid $primary-color;
                }
            }
        }

        &_ligne {
            display: none;
            width: 100%;
            padding-top: 20px;
        }
    }

    &_bloc {        
        display: none;

        &_main {    
            @include display-flex(row);
            justify-content: center;
            gap: 15px;
            margin: 0px -20px;
        }

        &_fleche {
            align-self: center;
            padding: 10px 5px;
            margin-bottom: 120px;

            &#fleche_gauche_promo, &#fleche_gauche_stock {
                color: $tertiary-color;
            }

            &#fleche_droite_promo, &#fleche_droite_stock {
                cursor: pointer;
            }
        }
        
        &_fige {
            width: 620px;
            @include display-flex(row);
            overflow: hidden;
            padding-top: 40px; 
        }

        &_article {
            width: fit-content;
            position: relative;
            @include display-flex(row);
            gap: 20px;

            &_prix {
                height: 100%;
                width: 300px;
                @include display-flex(column);
                gap: 15px;
            }

            &_un, &_deux, &_trois, &_quatre {
                height: 100%;
                width: 25%;
                @include display-flex(column);
                position: relative;
                overflow: hidden;
                gap: 20px;

                & .banniereTop {
                    padding: 5px 43px;
                    position: absolute;
                    top: 35px;
                    left: -40px;
                    font-size: 13px;
                    background-color: $primary-color;
                    color: $white-color;
                    transform: rotate(-45deg);
                }
            }

            &_photo {
                display: flex;
                width: 100%;
                position: relative;
                color: $black-color;
        
                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                &:hover {
                    & .misEnAvant_bloc_article_photo {
                        &_un {
                            display: none;
                        }

                        &_deux {
                            display: flex;
                        }
                    }
                }
        
                & img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &_deux{
                    display: none;
                }
            }

            &_info {
                @include display-flex(column);
                gap: 10px;
                font-size: 20px;

                &_nom {
                    font-size: 16px;

                    &:hover {
                        color: $primary-color;
                    }
                }

                &_cat {
                    height: 30px;
                    font-size: 14px;
                }

                &_prix {
                    @include display-flex(row);
                    align-items: flex-end;
                    gap: 3%;
                    color: $primary-color;

                    &_gauche {
                        font-size: 20px;
                        font-weight: bold;

                        &_offre {
                            font-size: 20px;
                            font-weight: bold;
                            color: red;
                        }

                        &_remise {
                            font-size: 20px;
                            font-weight: bold;
                        }

                        &_avant {
                            font-size: 16px;
                            text-decoration: line-through;
                            color: red;
                        }
                    }

                    &_droite {
                        font-size: 14px;

                        &_offre {
                            font-size: 16px;
                            font-weight: bold;
                            color: red;
                        }
        
                        &_remise {
                            font-size: 16px;
                            font-weight: bold;
                        }

                        &_avant {
                            font-size: 14px;
                            text-decoration: line-through;
                            color: red;
                        }
                    }
                    
                    &_barre {
                        @include display-flex(row);
                        align-items: flex-end;
                        gap: 3%;
                        font-size: 16px;
                        color: red;
                        text-decoration: line-through;

                        &_gauche {
                            font-size: 16px;
                            font-weight: bold;
                        }
    
                        &_droite {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        &_bouton {
            display: flex;
            justify-content: center;
            padding-top: 20px;

            &_un {
                border-radius: 20px;
                padding: 10px 20px;
                background-color: $primary-color;
                color: $tertiary-color;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: red;
                    transition: all 0.4s linear;
                }
            }
        }

        &.show-bloc {
            @include display-flex(column);
        }
    }
}

/* Sections Presentation / Accompagnement / Pro */
.presentation, .accompagnement, .pro {
    height: auto;
    @include display-flex(row);
    padding-top: 60px;
    gap: 20px;

    &_bloc {
        height: auto;
        width: 40%;
        @include display-flex(column);
        justify-content: space-evenly;
        border-radius: 10px;
        gap: 20px;

        & h1, & p {
            font-size: 18px;
        }

        & h2 {
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            letter-spacing : 1px;
        }

        &_titre, &_texte {
            @include display-flex(column);
            gap: 16px;

            &_ligne {
                width: 150px;
                align-self: center;
                border-bottom: 3px solid $black-color;    
            }
        }
    }

    &_photo {
        height: auto;
        max-height: 700px;
        width: 60%;
        display: flex;

        & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}

.presentation, .pro, .accompagnement {
    &_bloc {
        &_texte {
            font-family: $font-family-secondary, $font-family-alternate, $font-family-default !important;
            text-align: center;
        }
    }
}

.pro {
    padding-bottom: 60px;
}

.boutons {
    @include display-flex(column);
    justify-content: center;
    gap: 30px;
}

.bouton {
    &_accompagnement, &_pro {
        display: flex;
        align-self: center;
        
        & a {
            font-weight: bold;
            font-size: 20px;
            border: 2px solid $black-color;
            border-radius: 20px;
            padding: 8px 16px;
    
            &:hover {
                color: $tertiary-color;
                background-color: $primary-color;
                border-color: $primary-color;
                transition: all 0.6s linear;
            }
        }
    }
}

/* Showroom */
.showroom {
    height: auto;
    @include display-flex(column);
    align-items: center;
    gap: 40px;

    &_titre {
        font-size: 46px;
        font-weight: bold;
    }

    &_bloc {
        width: 100%;
        @include display-flex(row);
        gap: 50px;

        &_deux {
            height: 100%;
            width: 50%;
            @include display-flex(column);
            justify-content: space-between;
            text-align: center;
            align-items: center;
            gap: 20px;

            & img {
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-radius: 20px;
                cursor: zoom-in;

                &:hover {
                    opacity: 0.7;
                }
            }

            & a:hover {
                text-decoration: underline;
            }
            
            &_texte {
                height: auto;
                width: 100%;
                @include display-flex(column);
                justify-content: center;
                font-family: $font-family-secondary, $font-family-alternate, $font-family-default;
                gap: 10px;

                & h3 {
                    font-family: $font-family-primary, $font-family-secondary, $font-family-alternate, $font-family-default;
                    font-size: 32px;
                    color: $primary-color;
                }

                & a, & p {
                    font-size: 20px;
                }
            }

            &_fullscreen {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.95);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 50;

                & img {
                    max-width: 90%;
                    max-height: 90%;
                    object-fit: contain;
                    cursor: zoom-out;
                }

                &_croixFullscreen {
                    position: absolute;
                    top: 50px;
                    right: 50px;
                    transform: translateY(-50%);
                    color: $white-color;
                    transition: all 0.4s;
                    cursor: pointer;
                    z-index: 100;
    
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    &_adresse {
        font-weight: bold;
        font-style: italic;
    }
}

.articlesEnStock, .articlesEnPromo {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}

/* Grand Ecran */
@media all and (min-width: 1240px) {
    .misEnAvant {
        &_bloc {            
            &_fige {
                width: 940px;
            }
        }
    }
}

@media all and (min-width: 1560px) {
    .misEnAvant {
        &_bloc {            
            &_fige {
                width: 1260px;
            }
        }
    }
}

@media all and (min-width: 1880px) {
    .misEnAvant {
        &_bloc {            
            &_fige {
                width: 1580px;
            }
        }
    }
}

@media all and (min-width: 2200px) {
    .misEnAvant {
        &_bloc {            
            &_fige {
                width: 1900px;
            }
        }
    }
}

@media all and (min-width: 1399px) {
    .presentation, .pro, .accompagnement {
        &_bloc {
            padding: 30px 0px 30px 50px;
        }
    }
}

/* Petit Ecran */
@media all and (max-width: 1199px) {
    .mainHome {
        margin: 0px 100px;

        & .plus {
            margin : 50px -100px;
        }
    }

    .gallery {
        &_titre {
            &_main, &_ten, &_san {
                & h2 {
                    font-size: 46px;
                }

                & h3 {
                    font-size: 24px;
                }
            }
        }

        &_deux {
            font-size: 40px;
        }

        &_quatre {
            font-size: 28px;
        }

        &_cinq {
            font-size: 36px;
        }
    }

    .misEnAvant {
        & h3 {
            font-size: 22px;
        }

        &_bloc_article {
            &_un, &_deux, &_trois {
                width: 33.33%;
            }

            &_quatre {
                display: none;
            }

            &_info {
                font-size: 18px;

                &_prix_barre {
                    font-size: 14px;
                }
            }
        }
    }

    .presentation, .accompagnement, .pro {
        &_bloc {
            & h2 {
                font-size: 26px;
            }

            & h1, & p {
                font-size: 16px;
            }
        }
    }

    .bouton {
        &_accompagnement, &_pro {
            & a {
                font-size: 18px;
            }
        }
    }

    .showroom {
        &_titre {
            font-size: 40px;
        }

        &_bloc_deux_texte {
            & h3 {
                font-size: 26px;
            }

            & a, & p {
                font-size: 18px;
            }
        }
    }
}

/* Tablet */
@media all and (max-width: 820px) {
    .misEnAvant {
        &_bloc {            
            &_fige {
                width: 300px;
            }
        }
    }
}

@media all and (max-width: 767px) {
    /* Smartphone */
    html, body {
        background-image: none;
    }

    .mainHome {
        margin: 0px 75px;

        & .plus {   
            margin : 60px -75px;
        }
    }

    .gallery {
        &_titre {
            &_main, &_ten, &_san {
                & h2 {
                    font-size: 36px;
                }

                & h3 {
                    font-size: 18px;
                }
            }
        }

        &_car {
            flex-direction: column;
        }

        &_par {
            flex-direction: column-reverse;
        }

        &_ten {
            flex-direction: column;

            & .gallery_six, & .gallery_sept {
                width: 100%;
            }
        }

        &_san {
            flex-direction: column;
            width: 100%;
            
            & .gallery_cinq {
                width: 100%;
            }
        }
        
        &_car, &_par {
            & .gallery_deux {
                height: 50%;
                width: 100%;
            }
        }
        
        &_un {
            gap: 4%;
        }

        &_deux {
            font-size: 30px;
        }

        &_quatre {
            font-size: 22px;
        }

        &_cinq {
            font-size: 26px;
        }

        &_six {
            font-size: 50px;
        }

        &_huit {
            font-size: 42px;
        }

        &_neuf {
            font-size: 36px;
        }
    }

    .misEnAvant {
        & h3 {
            font-size: 18px;
        }

        &_bloc_article {
            &_un, &_deux {
                width: 50%;
            }

            &_trois, &_quatre {
                display: none;
            }
        }
    }

    .presentation, .accompagnement, .pro {
        gap: 10px;
        padding-top: 30px;

        &_photo {
            width: 40%;
        }

        &_bloc {
            width: 60%;

            & h2 {
                font-size: 22px;
            }
    
            & h1, & p {
                font-size: 12px;
            }
        }

        /*&_promo {
            bottom: 10px;
            left: 10px;
            
            & h2 {
                font-size: calc(1.4em + 1.4vw);
                font-weight: bolder;
            }
    
            & h3 {
                font-size: calc(1em + 1vw);
                font-weight: bold;
            }
        }*/
    }

    .pro {
        padding-bottom: 30px;
    }

    .gallery {
        padding: 10px;
        margin-top: 90px;
    }

    .misEnAvant {
        padding: 30px 10px 0px 10px;
        
        &_bloc {
            padding-top: 20px;
        }
    }

    .showroom {
        margin-bottom: 30px;
        
        &_titre {
            font-size: 32px;
        }

        &_bloc {
            @include display-flex(column);

            &_deux {
                width: 100%;
                gap: 10px;

                & img {
                    height: 250px;
                }

                &_texte {
                    gap: 0px;
                    
                    & h3 {
                        font-size: 22px;
                    }

                    & a, & p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

/* Smartphone */
@media all and (max-width: 549px) {
    .bodyMain {
        background-image: none;
    }

    .mainHome {
        margin: 0px 30px;

        & .plus {   
            margin: 30px -40px;
        }
    }

    .gallery {
        margin: 60px 10px 30px 10px;

        &_titre {
            &_main, &_ten, &_san {
                & h2, & h4 {
                    font-size: 30px;
                }
            }

            &_main {
                padding: 10px 0px 0px 0px;
            }

            &_san {
                padding: 0px;
            }
        }

        &_un_titre {
            font-size: 40px;
        }

        &_deux {
            font-size: 26px;
        }

        &_quatre {
            font-size: 20px;
        }

        &_cinq {
            font-size: 23px;
        }

        &_six {
            font-size: 46px;
        }

        &_huit {
            font-size: 36px;
        }

        &_neuf {
            font-size: 28px;
        }
    }

    .misEnAvant {
        padding: 30px 0px 0px 0px;

        &_bloc {
            width: calc(100% + 40px);
            gap: 5px;
            
            &_article {
                &_un, &_deux {
                    width: 50%;
                }

                &_trois, &_quatre {
                    display: none;
                }

                &_info {
                    font-size: 16px;

                    &_prix_barre {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .presentation, .pro, .accompagnement {
        &_bloc_titre {
            display: none;
        }
    }

    /*.presentation_promo {
        & h2 {
            font-size: calc(1.2em + 1.2vw);
        }

        & h3 {
            font-size: calc(0.9em + 0.9vw);
        }
    }*/

    .showroom {
        padding: 0px 30px;
        
        &_titre {
            text-align: center;
            font-size: 26px;
        }
        
        &_bloc {
            &_texte {
                font-size: calc(1em + 1vw);
            }

            &_deux {
                &_texte {
                    & h3 {
                        font-size: 20px;
                    }

                    & a, & p {
                        font-size: 14px;
                    }
                }

                & img {
                    height: 200px;
                }
            }
        }
    }
}

@media all and (max-width: 449px) {
    .presentation, .accompagnement, .pro {       
        &_bloc {
            & h1, & p {
                font-size: 12px;
            }
        }
    }

    .bouton_accompagnement, .bouton_pro {
        & a {
            font-size: 14px;
        }
    }
}